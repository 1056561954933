import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
// @mui
import {
  Card,
  Grid,
  Link,
  Box,
  Modal,
  Button,
  Container,
  Typography,
  IconButton,
  TextField,
  InputLabel,
  InputAdornment,
  styled,
} from '@mui/material';
import { toast } from 'react-toastify';
import { axios } from '../utils/axios';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';
// sections
import { LoginForm } from '../sections/auth/login';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function LoginPage() {
  const navigate = useNavigate();
  const mdUp = useResponsive('up', 'md');
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phoneCode, setPhoneCode] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const openModal = () => {
    setOpen(true);
  };

  const openModalClose = () => {
    setOpen(false);
  };
  const createAdmin = async () => {
    setOpen(false);
    try {
      const res = await axios.post('module/auth/admin', {
        email,
        password,
        phoneCode,
        firstname,
        lastname,
      });
      if (res.status === 200) {
        navigate('/login');
        toast.success('Admin created successfully');
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  return (
    <>
      <Helmet>
        <title> Login | Admin Panel </title>
      </Helmet>

      <StyledRoot>
        {mdUp && (
          <StyledSection>
            <Box
              component="img"
              src="./assets/BoatStar.png"
              sx={{
                width: 208,
                display: 'inline-flex',
                lg: 0,
              }}
            />
            <Typography variant="h3" sx={{ px: 5, mt: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img src="./assets/illustrations/illustration_login.png" alt="login" />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              Sign in to Admin Panel
            </Typography>

            <Typography variant="body2" sx={{ mb: 5 }}>
              Don’t have an account? {''}
              <Link variant="subtitle2" onClick={openModal}>
                Get started
              </Link>
            </Typography>

            {/* <Stack direction="row" spacing={2}>
              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:google-fill" color="#DF3E30" width={22} height={22} />
              </Button>

              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:facebook-fill" color="#1877F2" width={22} height={22} />
              </Button>

              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:twitter-fill" color="#1C9CEA" width={22} height={22} />
              </Button>
            </Stack>

            <Divider sx={{ my: 3 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                OR
              </Typography>
            </Divider> */}

            <LoginForm />
          </StyledContent>
        </Container>
      </StyledRoot>

      <Modal
        open={open}
        onClose={openModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={style1}>
          <Typography className="text-center" variant="h5" component="h4">
            Create Admin
          </Typography>
          <Grid container sx={{ marginTop: '10px' }}>
            <Grid item xs={6} sx={{ padding: '0px 5px' }}>
              <InputLabel id="demo-simple-select-label">First Name</InputLabel>
              <TextField
                sx={{ margin: '10px 0px', width: '100%' }}
                name="FirstName"
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
              />
            </Grid>
            <Grid item xs={6} sx={{ padding: '0px 5px' }}>
              <InputLabel id="demo-simple-select-label">Last Name</InputLabel>
              <TextField
                sx={{ margin: '10px 0px', width: '100%' }}
                name="LastName"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
              />
            </Grid>
            <Grid item xs={6} sx={{ padding: '0px 5px' }}>
              <InputLabel id="demo-simple-select-label">Phone number</InputLabel>
              <TextField
                sx={{ margin: '10px 0px', width: '100%' }}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                value={phoneCode}
                onChange={(e) => setPhoneCode(e.target.value)}
              />
            </Grid>
            <Grid item xs={6} sx={{ padding: '0px 5px' }}>
              <InputLabel id="demo-simple-select-label">Email</InputLabel>
              <TextField
                sx={{ margin: '10px 0px', width: '100%' }}
                type="email"
                name="EmailAddress"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={6} sx={{ padding: '0px 5px' }}>
              <InputLabel id="demo-simple-select-label">Password</InputLabel>
              <TextField
                sx={{ margin: '10px 0px', width: '100%' }}
                type={showPassword ? 'text' : 'password'}
                value={password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" sx={{ paddingTop: '20px' }}>
            <Button variant="contained" size="medium" color="primary" onClick={() => createAdmin()}>
              Create Account
            </Button>
          </Grid>
        </Card>
      </Modal>
    </>
  );
}
