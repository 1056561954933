import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Grid,
  styled,
} from '@mui/material';
import { toast } from 'react-toastify';
import { axios } from '../utils/axios';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'Id', alignRight: false },
  { id: 'category', label: 'Amenity Category', alignRight: false },
  { id: 'desc', label: 'Desc', alignRight: false },
  { id: 'createdAt', label: 'Created At', alignRight: false },
  { id: 'updatedAt', label: 'Updated At', alignRight: false },
  { id: 'amenitydata', label: 'View Amenity Data', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
const ColorButton = styled(Button)(({ theme }) => ({
  color: 'white',
  backgroundColor: 'red',
}));
const label = { inputProps: { 'aria-label': 'Size switch demo' } };

export default function UserPage() {
  const navigate = useNavigate();
  const [search, setsearch] = useState('');
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [amenityList, setAmenityList] = React.useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const fetchData = async () => {
    try {
      let url = `module/admin/amenityCategory?_page=${page}&_limit=${rowsPerPage}`;
      if (search) {
        url += `&search=${search}`;
        setPage(1);
      }
      const res = await axios.get(url);
      if (res.status === 200) {
        setAmenityList(res.data.data);
        setTotalCount(res.data.totalCount);
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, order, orderBy, search]);

  const handleformdata = async (ids, cate, des) => {
    const type = 'Amenity Category';
    const action = 'Edit';
    navigate(`/dashboard/editamenitycategory/${ids}`, {
      state: { process: action, types: type, id: ids, categories: cate, descs: des },
    });
  };

  const deleteAmenity = async (ids) => {
    try {
      const res = await axios.delete(`module/admin/amenityCategory/${ids}`);
      if (res.status === 200) {
        toast.info(res.data.message);
        fetchData();
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = amenityList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleOpenModal = () => {
    const type = 'Amenity Category';
    const action = 'Add';
    navigate('/dashboard/addamenity', { state: { types: type, process: action } });
  };

  const getAmenityData = (ids, category) => {
    navigate(`/dashboard/amenitydata/${ids}`, { state: { catid: ids, cate: category } });
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(1);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(1);
    setFilterName(event.target.value);
  };

  return (
    <>
      <Helmet>
        <title> List Amenity Category | Admin panel </title>
      </Helmet>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          List Amenity Category
        </Typography>
        <div>
          {/* <Button variant="contained" onClick={() => getAmenityData()}  sx={{ marginRight: "20px" }}>
            Get AmenityData
          </Button> */}
          <Button variant="contained" onClick={handleOpenModal} startIcon={<Iconify icon="eva:plus-fill" />}>
            Add Amenity Category
          </Button>
        </div>
      </Stack>

      <Card>
        <UserListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
          search={search}
          setsearch={setsearch}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={amenityList.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />

              <TableBody>
                {amenityList.map((row) => {
                  const { id, name } = row;
                  const selectedUser = selected.indexOf(name) !== -1;

                  return (
                    <TableRow
                      sx={{
                        '&:nth-of-type(odd)': {
                          backgroundColor: '#DDDDDD',
                        },
                        '&:last-child td, &:last-child th': {
                          border: 0,
                        },
                      }}
                      key={id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={selectedUser}
                    >
                      <TableCell padding="checkbox">
                        {/* <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} /> */}
                      </TableCell>
                      <TableCell component="th" scope="row" padding="none">
                        {row._id}
                      </TableCell>
                      <TableCell align="left">{row.category}</TableCell>
                      <TableCell align="left">{row.desc}</TableCell>
                      <TableCell align="left">{row.createdAt}</TableCell>
                      <TableCell align="left">{row.updatedAt}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => getAmenityData(row._id, row.category)}>
                          <Iconify color="disabled" icon={'carbon:view'} />
                        </IconButton>
                      </TableCell>
                      <TableCell align="left">
                        <IconButton onClick={() => handleformdata(row._id, row.category, row.desc)} sx={{ mr: 2 }}>
                          <Iconify icon={'eva:edit-fill'} />
                        </IconButton>
                        <IconButton onClick={() => deleteAmenity(row._id)}>
                          <Iconify icon={'eva:trash-2-outline'} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>

              {amenityList.length === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalCount || '0'}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </>
  );
}
