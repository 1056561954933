import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Card, Grid, Button, Typography, IconButton, InputAdornment, Box } from '@mui/material';
import { toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import { axios } from '../utils/axios';
import Iconify from '../components/iconify';

export default function AddaminPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [iconname, setIconName] = useState('');
  const [iconlabel, setIconLabel] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [imagepreview, setImagepreview] = useState(null);
  const { types, process } = location.state;
  const [iconNameError, setIconNameError] = useState('');
  const [iconError, setIconError] = useState('');

  const isAddButtonDisabled = !iconname || !iconlabel || !imageFile || iconNameError || iconError;

  const backtouser = () => {
    navigate('/dashboard/icon');
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImagepreview(reader.result);
      setImageFile(file);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const Addicon = async () => {
    try {
      const formData = new FormData();
      formData.append('name', iconname);
      formData.append('label', iconlabel);
      formData.append('icon', imageFile);

      const res = await axios.post('module/admin/icon', formData);

      if (res.status === 200) {
        toast.success(res.data.message);
        navigate('/dashboard/icon');
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };
  const handleIconNameChange = (e) => {
    const inputValue = e.target.value;
    setIconName(inputValue);

    // Regular expression to match only letters
    const lettersOnlyRegex = /^[A-Za-z]+$/;

    if (!lettersOnlyRegex.test(inputValue)) {
      setIconNameError('invalid');
    } else {
      setIconNameError('');
    }
  };
  const handleIconLabelChange = (e) => {
    const inputValue = e.target.value;
    setIconLabel(inputValue);

    // Regular expression to match only letters
    const lettersOnlyRegex = /^[A-Za-z]+$/;

    if (!lettersOnlyRegex.test(inputValue)) {
      setIconError('invalid');
    } else {
      setIconError('');
    }
  };
  return (
    <>
      <Helmet>
        <title> {process} Icon </title>
      </Helmet>
      <Card sx={{ padding: '20px 10px', margin: '30px 0px' }}>
        <Grid container sx={{ borderBottom: '1px solid #dbe0e4', padding: '10px 0px' }}>
          <Grid container item justifyContent="space-between" alignItems="center" sx={{ marginRight: '10px' }}>
            <Typography variant="h6" gutterBottom sx={{ margin: '0px 0px 0px 10px', paddingBottom: '0px' }}>
              {process} {types}
            </Typography>
            <Button variant="contained" onClick={backtouser} startIcon={<Iconify icon="icon-park-twotone:back" />}>
              Go Back
            </Button>
          </Grid>
        </Grid>
        <Grid container sx={{ marginTop: '10px' }}>
          <Grid item xs={6} sx={{ padding: '0px 5px' }}>
            <InputLabel id="demo-simple-select-label"> Name</InputLabel>
            <TextField
              sx={{ margin: '10px 0px', width: '100%' }}
              name="FirstName"
              value={iconname}
              onChange={handleIconNameChange}
              error={!!iconNameError}
              helperText={iconNameError}
            />
          </Grid>
          <Grid item xs={6} sx={{ padding: '0px 5px' }}>
            <InputLabel id="demo-simple-select-label">Label</InputLabel>
            <TextField
              sx={{ margin: '10px 0px', width: '100%' }}
              name="LastName"
              value={iconlabel}
              onChange={handleIconLabelChange}
              error={!!iconError}
              helperText={iconError}
            />
          </Grid>
        </Grid>

        <Grid container xs={6} sx={{ paddingRight: '0px' }} justifyContent="center" alignItems="center">
          <Grid sx={6}>
            <InputLabel
              sx={6}
              id="demo-simple-select-label"
              position={'absolute'}
              style={{
                cursor: 'pointer',
                border: '1px solid #adb5bd',
                borderRadius: '5px',
                padding: '15px',
                marginTop: '0px',
                marginLeft: '0px',
                right: '162px',
              }}
            >
              <input type="file" id="image-upload" style={{ display: 'none' }} onChange={(e) => handleImageChange(e)} />
              <span style={{ color: 'black' }}>Upload Icon</span>
              {imagepreview && (
                <img
                  src={imagepreview}
                  alt="Preview"
                  style={{ maxWidth: '50px', position: 'relative', left: '17px', width: '70px', marginTop: '8px' }}
                />
              )}
            </InputLabel>
          </Grid>
        </Grid>

        <Grid container justifyContent="flex-end" position={'absolute'} sx={{ paddingRight: '20px', bottom: '10px' }}>
          <Button variant="contained" disabled={isAddButtonDisabled} onClick={() => Addicon()}>
            Add
          </Button>
        </Grid>
      </Card>
    </>
  );
}
