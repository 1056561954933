import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  styled,
  Modal,
  Box,
  TextField,
  Grid,
  InputAdornment,
} from '@mui/material';
import WalletIcon from '@mui/icons-material/Wallet';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { toast } from 'react-toastify';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { UserVerify, UserNotVerify } from '../components/icons/UserVerify';
import { axios } from '../utils/axios';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'Id', alignRight: false },
  { id: 'name', label: 'Full Name', alignRight: false },
  // { id: 'gender', label: 'Gender', alignRight: false },
  // { id: 'dob', label: 'Date of Birth', alignRight: false },
  { id: 'mail', label: 'Email ID', alignRight: false },
  // { id: 'phone', label: 'Phone Number', alignRight: false },
  { id: 'verified', label: 'Verified', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  // { id: 'payments', label: 'Payments', alignRight: false },
  // { id: 'password', label: 'Reset / Change Password', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

// ----------------------------------------------------------------------
const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
const ColorButton = styled(Button)(({ theme }) => ({
  color: 'white',
  backgroundColor: 'red',
}));
const label = { inputProps: { 'aria-label': 'Size switch demo' } };

// const formatDate = (dob) => {
//   const dateObj = new Date(dob);
//   const day = dateObj.getDate().toString().padStart(2, '0');
//   const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
//   const year = dateObj.getFullYear().toString();
//   return `${day}-${month}-${year}`;
// };
// console.log('date', formatDate);

export default function UserPage() {
  const navigate = useNavigate();
  const [opens, setOpens] = useState(false);
  const [openResetModal, setOpenResetModal] = useState(false);
  const [changedPassword, setChangedPassword] = useState('');
  const [currentPasswords, setCurrentPasswords] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordc, setShowPasswordc] = useState(false);
  const [search, setsearch] = useState('');
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [userList, setUserList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [verify, setVerify] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const { vertical, horizontal, open } = verify;

  const fetchData = async () => {
    try {
      let url = `module/auth/users?_page=${page}&_limit=${rowsPerPage}`;
      if (search) {
        url += `&search=${search}`;
        setPage(1);
      }
      const res = await axios.get(url);
      if (res.status === 200) {
        setUserList(res.data.data);
        setTotalCount(res.data.totalCount);
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, order, orderBy, search]);

  const handleformdata = async (ids) => {
    try {
      const res = await axios.get(`module/auth/user/${ids}`);
      if (res.status === 200) {
        navigate('/dashboard/edituser', {
          state: { datas: res.data.data, editId: ids, types: res.data.type },
        });
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  // const resetModal = async (ids) => {
  //   sessionStorage.setItem('resetUserId', ids);
  //   setOpenResetModal(true);
  //   const userId = sessionStorage.getItem('resetUserId');
  //   try {
  //     const res = await axios.post(`module/auth/user/password`, { userId });
  //     if (res.status === 200) {
  //       // window.location.reload(true)
  //     } else {
  //       toast.error(res.data.message, {
  //         position: 'top-right',
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: 'light',
  //       });
  //     }
  //   } catch (err) {
  //     toast.error(err.response.data.message, {
  //       position: 'top-right',
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: 'light',
  //     });
  //   }
  // };

  // const changePassword = async () => {
  //   const userId = sessionStorage.getItem('resetUserId');
  //   const newPassword = changedPassword;
  //   const currentPassword = currentPasswords;

  //   try {
  //     const res = await axios.put(`module/auth/user/password`, { userId, newPassword, currentPassword });
  //     if (res.status === 200) {
  //       // window.location.reload(true)
  //       setOpenResetModal(false);
  //     } else {
  //       toast.error(res.data.message, {
  //         position: 'top-right',
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: 'light',
  //       });
  //     }
  //   } catch (err) {
  //     toast.error(err.response.data.message, {
  //       position: 'top-right',
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: 'light',
  //     });
  //   }
  // };

  const deleteModal = (ids) => {
    sessionStorage.setItem('userId', ids);
    setOpens(true);
  };

  const deleteUser = async () => {
    const ids = sessionStorage.getItem('userId');
    try {
      const res = await axios.delete(`module/auth/user/${ids}`);
      if (res.status === 200) {
        setOpens(false);
        toast.info(res.data.message);
        fetchData();
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = userList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleOpenModal = () => {
    const type = 'User';
    navigate('/dashboard/addprofile', { state: { types: type } });
  };
  // const createwallet = (ids, fname, lname) => {
  //   const type = 'User';
  //   navigate('/dashboard/wallet', { state: { ids, fname, lname, types: type } });
  // };
  const userVerify = async (ids, boolean) => {
    try {
      const res = await axios.put(`module/auth/user/VerifyAndActiveStatus/${ids}`, { verify: boolean });
      if (res.status === 200) {
        window.location.reload(true);
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  const userActive = async (ids, boolean) => {
    try {
      const res = await axios.put(`module/auth/user/VerifyAndActiveStatus/${ids}`, { active: boolean });
      if (res.status === 200) {
        window.location.reload(true);
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(1);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(1);
    setFilterName(event.target.value);
  };

  return (
    <>
      <Helmet>
        <title> User | Admin panel </title>
      </Helmet>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          User List
        </Typography>
        <Button variant="contained" onClick={handleOpenModal} startIcon={<PersonAddAltIcon />}>
          Add User
        </Button>
      </Stack>

      <Card>
        <UserListToolbar
          numSelected={selected.length}
          filterName={filterName}
          search={search}
          setsearch={setsearch}
          onFilterName={handleFilterByName}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={userList.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />

              <TableBody>
                {userList.map((row) => {
                  const { id, name } = row;
                  const selectedUser = selected.indexOf(name) !== -1;

                  return (
                    <TableRow
                      sx={{
                        '&:nth-of-type(odd)': {
                          backgroundColor: '#DDDDDD',
                        },
                        '&:last-child td, &:last-child th': {
                          border: 0,
                        },
                      }}
                      key={id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={selectedUser}
                    >
                      <TableCell padding="checkbox">
                        {/* <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} /> */}
                      </TableCell>

                      <TableCell component="th" scope="row" padding="none">
                        {row._id}
                      </TableCell>

                      <TableCell align="left">
                        {row.firstname} {row.lastname}
                      </TableCell>
                      {/* <TableCell align="left">{row.gender}</TableCell> */}
                      {/* <TableCell style={{ whiteSpace: 'nowrap' }} align="left">
                        {formatDate(row.DOB)}
                      </TableCell> */}

                      <TableCell align="left">{row.email}</TableCell>
                      {/* <TableCell align="left">{row.phone}</TableCell> */}

                      <TableCell align="left">
                        {!row.verified ? (
                          <Label sx={{ width: '40px', height: '40px' }} color="error">
                            <UserNotVerify />
                          </Label>
                        ) : (
                          <Label sx={{ width: '40px', height: '40px' }} color="success">
                            <UserVerify />
                          </Label>
                        )}
                      </TableCell>

                      <TableCell align="left">
                        {!row.isActive ? <Label color="error">InActive</Label> : <Label color="success">Active</Label>}
                      </TableCell>

                      {/* <TableCell align="left">
                         <Label color="error" onClick={() => resetModal(row._id)}>
                          Reset Password
                        </Label> 
                      </TableCell> */}
                      {/* <TableCell align="left">
                        <Label onClick={() => createwallet(row._id, row.firstname, row.lastname)}>
                          <img src="/assets/icons/card.svg" alt="Create Wallet" />
                        </Label>
                      </TableCell> */}
                      <TableCell align="left">
                        <IconButton onClick={() => handleformdata(row._id)} sx={{ mr: 2 }}>
                          <Iconify icon={'carbon:view'} />
                        </IconButton>
                        <IconButton onClick={() => deleteModal(row._id)}>
                          <Iconify icon={'eva:trash-2-outline'} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>

              {userList.length === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          No data found
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>

      <Modal
        open={opens}
        onClose={() => setOpens(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style1}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to delete ?
          </Typography>
          <div className="d-flex justify-content-around mt-3">
            <Button variant="contained" size="medium" color="secondary" onClick={() => setOpens(false)}>
              No
            </Button>
            <Button variant="contained" size="medium" color="primary" onClick={() => deleteUser()}>
              Yes
            </Button>
          </div>
        </Box>
      </Modal>

      {/* <Modal
        open={openResetModal}
        onClose={() => setOpenResetModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={style1}>
          <Grid>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Change Password
            </Typography>

            <TextField
              margin="normal"
              required
              fullWidth
              type={showPassword ? 'text' : 'password'}
              id="currentPassword"
              label="Current Password"
              name="current password"
              autoFocus
              onChange={(e) => setCurrentPasswords(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              type={showPasswordc ? 'text' : 'password'}
              id="newPassword"
              label="New Password"
              name="new password"
              onChange={(e) => setChangedPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPasswordc(!showPasswordc)} edge="end">
                      <Iconify icon={showPasswordc ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <div className="d-flex justify-content-around mt-3">
              <Button variant="contained" size="medium" color="secondary" onClick={() => setOpenResetModal(false)}>
                No
              </Button>
              <Button variant="contained" size="medium" color="primary" onClick={() => changePassword()}>
                Yes
              </Button>
            </div>
          </Grid>
        </Card>
      </Modal> */}

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={3000}
        open={open}
        onClose={() => setVerify({ ...verify, open: false })}
        key={vertical + horizontal}
      >
        <Alert onClose={() => setVerify({ ...verify, open: false })} severity="success" sx={{ width: '100%' }}>
          <AlertTitle>Verified</AlertTitle>
        </Alert>
      </Snackbar>
    </>
  );
}
