import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Grid,
  styled,
} from '@mui/material';
import { toast } from 'react-toastify';
import { axios } from '../utils/axios';
import { BASE_URL } from '../utils/config';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'bookingid', label: 'BookingId', alignRight: false },
  { id: 'bookedDate', label: 'Created', alignRight: false },
  { id: 'propertyid', label: 'PropertyId', alignRight: false },
  { id: 'propertyName', label: 'Property Name', alignRight: false },
  { id: 'category', label: 'Category', alignRight: false },
  { id: 'providerid', label: 'ProviderId', alignRight: false },
  { id: 'providername', label: 'ProviderName', alignRight: false },
  { id: 'night', label: 'Nights', alignRight: false },
  { id: 'hour', label: 'Hours', alignRight: false },
  { id: 'adults', label: 'Adults', alignRight: false },
  { id: 'children', label: 'Children', alignRight: false },
  { id: 'pets', label: 'Pets', alignRight: false },
  { id: 'perday', label: 'Amount / day', alignRight: false },
  { id: 'perhour', label: 'Amount / hour', alignRight: false },
  { id: 'host', label: 'HostAmount', alignRight: false },
  { id: 'commission', label: 'Admin Commission', alignRight: false },
  { id: 'startdate', label: 'CheckIn', alignRight: false },
  { id: 'enddate', label: 'CheckOut', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  // { id: 'cancellationpolicyid', label: 'PolicyId', alignRight: false },
  { id: 'fare', label: 'Rate', alignRight: false },
  { id: 'paiddate', label: 'Paid Date', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
const ColorButton = styled(Button)(({ theme }) => ({
  color: 'white',
  backgroundColor: 'red',
}));
const label = { inputProps: { 'aria-label': 'Size switch demo' } };

export default function UserPage() {
  const [search, setsearch] = useState('');
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [upcomingList, setUpcomingList] = useState([]);
  const [cancellationPolicy, setCancellationPolicy] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const fetchData = async () => {
    try {
      let url = `module/listing/trips/upcommingBooking?_page=${page}&_limit=${rowsPerPage}`;
      if (search) {
        url += `&search=${search}`;
        setPage(1);
      }
      const res = await axios.get(url);
      if (res.status === 200) {
        setUpcomingList(res.data.data[0].data);
        setTotalCount(res.data.data[0].totalCount[0]?.total);
        setCancellationPolicy(res.data.cancellationPolicy);
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };
  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, order, orderBy, search]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = upcomingList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(1);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(1);
    setFilterName(event.target.value);
  };

  return (
    <>
      <Helmet>
        <title> Manage Trips useEff| Admin panel </title>
      </Helmet>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Upcoming Trips
        </Typography>
      </Stack>

      <Card>
        <UserListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
          search={search}
          setsearch={setsearch}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={upcomingList.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />

              <TableBody>
                {upcomingList.map((row) => {
                  const { id, name } = row;
                  const selectedUser = selected.indexOf(name) !== -1;

                  return (
                    <TableRow
                      sx={{
                        '&:nth-of-type(odd)': {
                          backgroundColor: '#DDDDDD',
                        },
                        '&:last-child td, &:last-child th': {
                          border: 0,
                        },
                      }}
                      key={id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={selectedUser}
                    >
                      <TableCell padding="checkbox">
                        {/* <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} /> */}
                      </TableCell>
                      <TableCell align="left">{row._id}</TableCell>
                      <TableCell align="left">{row.bookingdata.createdAt?.split('T')[0]}</TableCell>
                      <TableCell align="left">{row.propertyCategoryName._id}</TableCell>
                      <TableCell align="left">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`${BASE_URL}detail/${row._id}`}
                          style={{ cursor: 'pointer', textDecoration: 'none', color: 'black', whiteSpace: 'nowrap' }}
                        >
                          {' '}
                          {row.propertyName}
                        </a>
                      </TableCell>
                      <TableCell align="left">{row.propertyCategoryName.category}</TableCell>
                      <TableCell align="left">{row.providerId}</TableCell>
                      <TableCell align="left">{row.providerFirstname}</TableCell>
                      <TableCell align="left">{row.bookingdata.bookedHours.nights}</TableCell>
                      <TableCell align="left">{row.bookingdata.bookedHours.hours}</TableCell>
                      <TableCell align="left">{row.bookingdata.adults}</TableCell>
                      <TableCell align="left">{row.bookingdata.children}</TableCell>
                      <TableCell align="left">{row.bookingdata.pets}</TableCell>
                      <TableCell align="left">{row.bookingdata.perDay}</TableCell>
                      <TableCell align="left">{row.bookingdata.perHour}</TableCell>
                      <TableCell align="left">{row.bookingdata.hostAmount}</TableCell>
                      <TableCell align="left">{row.bookingdata.commission}</TableCell>
                      <TableCell align="left">{row.bookingdata.bookedDates.start?.split('T')[0]}</TableCell>
                      <TableCell align="left">{row.bookingdata.bookedDates.end?.split('T')[0]}</TableCell>
                      <TableCell align="left">
                        <Label color="success">{row.bookingdata.status}</Label>
                      </TableCell>
                      {/* <TableCell align="left">
                                                {row.cancellationPolicyId}
                                            </TableCell> */}
                      <TableCell align="left">
                        {row.bookingdata.currency} {row.bookingdata.fareAmount}
                      </TableCell>
                      <TableCell align="left">{row.bookingdata.paidDate?.split('T')[0]}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>

              {upcomingList.length === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
      <Typography variant="h6" paragraph sx={{ my: 3, px: 3 }}>
        Notes *
      </Typography>
      <>
        {cancellationPolicy.map((item) => (
          <>
            <Typography sx={{ px: 3 }}>
              {item.id} - {item.title} : {item.desc}
            </Typography>
          </>
        ))}
      </>
    </>
  );
}
