/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { MenuItem, Button, Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { axios } from '../utils/axios';
import { IMAGE_URL } from '../utils/config';

function AdminProfile() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [data, setData] = useState(null);
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [eMails, setEMails] = useState('');
  const [phoneCodes, setPhoneCodes] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [dob, setDob] = useState('');
  const [genders, setGenders] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isAbove18, setIsAbove18] = useState(true);
  const [error, setError] = useState('');
  const [phoneCodeError, setPhoneCodeError] = useState('');
  const [imageUrl, setImageUrl] = useState(null);
  const isDisableButton = !isAbove18 || error || phoneCodeError || emailError;
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };
  const AdminProfileData = async () => {
    try {
      const res = await axios.get('module/auth/admin/profile');
      if (res.status === 200) {
        console.log('gv', res);
        setData(res?.data?.data);
        setImageUrl(res?.data?.data?.profileImage);
        console.log('gv', imageUrl);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    AdminProfileData();
  }, []);

  const EditAdmin = async () => {
    const updatedValues = new FormData();
    const editId = data._id;
    updatedValues.append('file', selectedFile || data?.profileImage);
    updatedValues.append('phone', phoneNumber || data?.phone);
    updatedValues.append('phoneCode', phoneCodes || data?.phoneCode);
    updatedValues.append('email', eMails || data?.email);
    updatedValues.append('DOB', (dob && dob?.format('L')) || data?.DOB);
    updatedValues.append('firstname', fname || data?.firstname);
    updatedValues.append('lastname', lname || data?.lastname);
    updatedValues.append('gender', genders || data?.gender);
    try {
      const res = await axios.put(`module/auth/admin/${editId}`, updatedValues);
      if (res.status === 200) {
        AdminProfileData();
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  const handleDateChange = (newValue) => {
    setDob(newValue);
    const currentDate = dayjs();
    const age = currentDate.diff(newValue, 'year');
    setIsAbove18(age >= 18);
  };
  const handleMailChange = (e) => {
    const emailInput = e.target.value;
    setEMails(emailInput);

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailInput)) {
      setEmailError('Invalid email format');
    } else {
      setEmailError('');
    }
  };
  const handlePhoneCodeChange = (e) => {
    const codeInput = e.target.value;
    setPhoneCodes(codeInput);

    if (!/^\d{1,3}$/.test(codeInput)) {
      setPhoneCodeError('Invalid phone code');
    } else {
      setPhoneCodeError('');
    }
  };
  const handleChange = (e) => {
    const numericInput = e.target.value;
    setPhoneNumber(numericInput);

    if (!/^\d{10}$/.test(numericInput)) {
      setError('Invalid phone number');
    } else {
      setError('');
    }
  };
  return (
    <>
      <Box>
        <Card sx={{ padding: '30px' }}>
          <Typography sx={{ fontWeight: 'bold', fontSize: '25px', textTransform: 'capitalize' }}>
            Admin Profile
          </Typography>
        </Card>
      </Box>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}>
        <Box sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)', marginTop: '0' }}>
          <Card sx={{ padding: '30px' }} variant="outlined">
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}>
              <div>
                {imageUrl ? (
                  <img
                    src={IMAGE_URL + imageUrl}
                    alt="Selected"
                    style={{
                      width: '80%',
                      height: '160px',
                      borderRadius: '50%',
                      objectFit: 'cover',
                    }}
                  />
                ) : (
                  <div>
                    <img
                      src="/assets/icons/proImage.png"
                      alt="profile"
                      style={{ borderRadius: '50%', width: '80%', height: '160px', objectFit: 'cover' }}
                    />
                  </div>
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div style={{ display: 'grid' }}>
                  <p style={{ fontWeight: 'bold', fontSize: '25px', textTransform: 'capitalize' }}>
                    {data?.firstname}
                    {data?.lastname}
                  </p>
                  <p style={{ fontWeight: '600', color: 'grey' }}>{data?.email}</p>
                  <p style={{ fontWeight: '600', color: 'grey' }}>{data?.gender}</p>
                  <p style={{ fontWeight: '600', color: 'grey' }}>{data?.DOB}</p>
                  <p style={{ fontWeight: '600', color: 'grey' }}>
                    {data?.phoneCode} - {data?.phone}
                  </p>
                  <p style={{ fontWeight: '600', color: 'grey' }}>{data?.email}</p>

                  <p style={{ fontWeight: '600', color: 'grey' }}>
                    {data?.isActive ? (
                      <span>
                        <span style={{ color: 'green', fontSize: '20px' }}>&#9679;</span> Active
                      </span>
                    ) : (
                      <span>
                        <span style={{ color: 'red', fontSize: '20px' }}>&#9679;</span> Inactive
                      </span>
                    )}
                  </p>
                  <p style={{ fontWeight: '600', color: 'grey' }}>
                    {data?.verified ? (
                      <span>
                        <span style={{ color: 'green', fontSize: '20px' }}>&#9679;</span> Verified
                      </span>
                    ) : (
                      <span>
                        <span style={{ color: 'red', fontSize: '20px' }}>&#9679;</span> Unverified
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </Card>
        </Box>
        <Box sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
          <Card variant="outlined" style={{ padding: '30px' }}>
            <InputLabel id="demo-simple-select-label">First Name</InputLabel>
            <TextField
              sx={{ margin: '10px 0px', width: '100%' }}
              name="FirstName"
              defaultValue={data?.firstname}
              onChange={(e) => {
                const textInput = e.target.value;
                // Use a regular expression to check for alphabetic characters only
                const validTextInput = textInput.replace(/[^a-zA-Z]/g, '');
                setFname(validTextInput);
              }}
              onKeyDown={(e) => {
                // Prevent typing numbers and special characters by capturing keydown events
                if (!/^[a-zA-Z]*$/.test(e.key)) {
                  e.preventDefault();
                }
              }}
            />
            <InputLabel id="demo-simple-select-label">Last Name </InputLabel>
            <TextField
              sx={{ margin: '10px 0px', width: '100%' }}
              name="LastName"
              defaultValue={data?.lastname}
              onChange={(e) => {
                const textInput = e.target.value;
                // Use a regular expression to check for alphabetic characters only
                const validTextInput = textInput.replace(/[^a-zA-Z]/g, '');
                setLname(validTextInput);
              }}
              onKeyDown={(e) => {
                // Prevent typing numbers and special characters by capturing keydown events
                if (!/^[a-zA-Z]*$/.test(e.key)) {
                  e.preventDefault();
                }
              }}
            />
            <InputLabel id="demo-simple-select-label">Gender</InputLabel>
            <FormControl fullWidth sx={{ margin: '10px 0px' }}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={genders}
                onChange={(e) => setGenders(e.target.value)}
                renderValue={(selected) => {
                  if (selected === '') {
                    return <em>{genders}</em>;
                  }
                  return selected;
                }}
              >
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </FormControl>
            <InputLabel id="demo-simple-select-label">Email</InputLabel>
            <TextField
              sx={{ margin: '10px 0px', width: '100%' }}
              name="Email"
              error={!!emailError}
              helperText={emailError}
              defaultValue={data?.email}
              onChange={handleMailChange}
            />
            <InputLabel id="demo-simple-select-label">Phone Code</InputLabel>
            <TextField
              sx={{ margin: '10px 0px', width: '100%' }}
              name="Phonecode"
              defaultValue={data?.phoneCode}
              error={!!phoneCodeError}
              helperText={phoneCodeError}
              onChange={handlePhoneCodeChange}
            />
            <InputLabel id="demo-simple-select-label">Phone Number</InputLabel>
            <TextField
              sx={{ margin: '10px 0px', width: '100%' }}
              name="phonenumber"
              defaultValue={data?.phone}
              helperText={error}
              error={!!error}
              onChange={handleChange}
              inputMode="numeric"
            />
            <InputLabel id="demo-simple-select-label">DOB</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
              <DemoContainer components={['DatePicker']}>
                <DatePicker
                  sx={{ width: 1 }}
                  // defaultValue={dayjs(data?.DOB)}
                  format="DD-MM-YYYY"
                  disableFuture
                  onChange={handleDateChange}
                />
              </DemoContainer>
            </LocalizationProvider>
            {!isAbove18 && <p style={{ color: 'red' }}>Age must be above 18</p>}
            <InputLabel id="demo-simple-select-label">Profile Pic</InputLabel>
            <TextField
              type="file"
              sx={{ margin: '10px 0px', width: '100%' }}
              name="profilepic"
              // defaultValue={datas.profileImage}
              onChange={(e) =>
                // console.log(e.target.files && e.target.files[0])
                setSelectedFile(e.target.files && e.target.files[0])
              }
            />
            <div style={{ marginTop: '10px' }}>
              <Button variant="contained" disabled={isDisableButton} onClick={() => EditAdmin()}>
                Update
              </Button>
            </div>
          </Card>
        </Box>
      </div>
    </>
  );
}

export default AdminProfile;
