import axios from 'axios';
// import { deleteCookie, getCookie } from 'cookies-next'
// console.log('process.env.API_URL', process.env.API_URL);
const api = axios.create({
  baseURL: 'https://boatstarapi.abservetechdemo.com/',
});
const cancelToken = () => axios.CancelToken.source();

if (typeof window !== 'undefined') {
  const token = window.localStorage.getItem('admintoken');
  api.defaults.headers.common.Authorization = token;
}

api.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error)
);
api.interceptors.response.use(
  (response) =>
    // console.log("response", response);
    response,
  (error) => {
    if (error?.response?.status === 401 && localStorage.getItem('admintoken')) {
      
      // alert('session expired')
      // localStorage.clear();
      // deleteCookie('auth');
      // window.location = '/';
    }
    if (error?.response?.status === 404) {
      console.log('unauthorized', error.response);
    }
    return Promise.reject(error);
  }
);
export { api as axios, cancelToken };
