import React, { useState, useEffect } from 'react';
import {
  Stack,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormLabel,
} from '@mui/material';
import { toast } from 'react-toastify';
import Autocomplete from '@mui/material/Autocomplete';
import FormGroup from '@mui/material/FormGroup';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import { axios } from '../utils/axios';

const InputContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  marginBottom: '16px',
});

const SendNotification = () => {
  const [showUsers, setShowUsers] = useState(false);
  const [showProviders, setShowProviders] = useState(false);
  const [userOptions, setUserOptions] = useState([]);
  const [userId, setUserId] = useState([]);
  const [providerId, setProviderId] = useState([]);
  const [providerOptions, setProviderOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedUserNames, setSelectedUserNames] = useState([]);
  const [selectedProviderNames, setSelectedProviderNames] = useState([]);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectedProviderIds, setSelectedProviderIds] = useState([]);
  const [usertype, setUserType] = useState('');
  const [providertype, Setprovidertype] = useState('');
  const [messages, setMessages] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [userList, setUserList] = useState([]);
  const [response, setResponse] = useState('');

  const options = showUsers ? userOptions : showProviders ? providerOptions : [];

  const isDisableButton = !messages || !usertype;

  const handleSendNotification = async (event) => {
    event.preventDefault();
    let forWhom = '[]';
    if (usertype === 'user') {
      forWhom = `[${selectedUserIds.toString()}]`;
    } else {
      forWhom = `[${selectedProviderIds.toString()}]`;
    }
    try {
      const data = {
        message: messages,
        userType: usertype.toUpperCase() || providertype.toUpperCase(),
        forWhom,
      };
      const res = await axios.post('/module/notifications/fromAdmin', data);

      if (res.status === 200) {
        toast.success('Notification sent successfully');
        setMessages('');
        setSearchTerm('');
      } else {
        toast.error('Failed to send notification');
      }
    } catch (err) {
      toast.error('Error sending notification: ', err.message);
    }
  };

  const handleUserChange = async (event) => {
    setShowUsers(event.target.checked);
    setUserType(event.target.checked ? 'user' : '');
    setShowProviders(false);
    if (event.target.checked) {
      try {
        const response = await axios.get('module/auth/users/list');
        setUserOptions(response.data.data.map((user) => user.firstname));
        setUserId(response.data.data.map((user) => user._id));
        console.log(response.data.data.map((user) => user._id));
        const userIdsAsNumbers = response.data.data.map((user) => user._id);
        console.log(userIdsAsNumbers);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    } else {
      setUserOptions([]);
    }
  };

  const handleProviderChange = async (event) => {
    setShowProviders(event.target.checked);
    Setprovidertype(event.target.checked ? 'provider' : '');
    setShowUsers(false);
    if (event.target.checked) {
      try {
        const response = await axios.get('module/auth/providers/list');
        setProviderOptions(response.data.data.map((provider) => provider.firstname));
        setProviderId(response.data.data.map((provider) => provider._id));
        console.log(providerId);
      } catch (error) {
        console.error('Error fetching provider data:', error);
      }
    } else {
      setProviderOptions([]);
    }
  };

  return (
    <div>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Send Notifications
        </Typography>
      </Stack>
      <form onSubmit={handleSendNotification} style={{ width: '50%', margin: '0 auto' }}>
        <Stack direction="column" spacing={2}>
          <TextField
            id="message"
            label="Message"
            variant="outlined"
            multiline
            rows={4}
            value={messages}
            onChange={(e) => setMessages(e.target.value)}
          />
          <FormControl component="fieldset">
            <FormLabel component="legend">Message To:</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={showUsers} onChange={handleUserChange} disabled={showProviders} />}
                label="User"
              />
              <FormControlLabel
                control={<Checkbox checked={showProviders} onChange={handleProviderChange} disabled={showUsers} />}
                label="Provider"
              />
            </FormGroup>
          </FormControl>

          {(showUsers || showProviders) && (
            <Stack spacing={2}>
              <InputContainer>
                <Autocomplete
                  multiple
                  id="tags-filled"
                  options={options}
                  defaultValue={[]}
                  onChange={(_, newValue) => {
                    setSelectedOptions(newValue);
                    console.log(selectedOptions);
                    if (showUsers) {
                      const selectedUserIds = newValue.map((name) => userId[userOptions.indexOf(name)]);
                      setSelectedUserNames(newValue);
                      setSelectedUserIds(selectedUserIds);
                      console.log(selectedUserIds);
                    }

                    if (showProviders) {
                      const selectedProviderIds = newValue.map((name) => providerId[providerOptions.indexOf(name)]);
                      setSelectedProviderNames(newValue);
                      setSelectedProviderIds(selectedProviderIds);
                      console.log(selectedProviderIds);
                    }
                  }}
                  value={selectedOptions}
                  // renderTags={(value, getTagProps) =>
                  //   value.map((option, index) => (
                  //     <Chip key={index} variant="outlined" label={option} {...getTagProps({ index })} />
                  //   ))
                  // }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Name"
                      placeholder="Type and select names"
                      style={{ width: '480px' }}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  )}
                />
              </InputContainer>
            </Stack>
          )}
          <Button type="submit" variant="contained" disabled={isDisableButton} color="primary">
            Send
          </Button>
          <br />
        </Stack>
      </form>
    </div>
  );
};

export default SendNotification;
