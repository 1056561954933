import React, { useState } from 'react';
import {
  Card,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormLabel,
  Grid,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import SendIcon from '@mui/icons-material/Send';
import Select from '@mui/material/Select';
import WalletIcon from '@mui/icons-material/Wallet';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import FormGroup from '@mui/material/FormGroup';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import { axios } from '../utils/axios';

const InputContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  marginBottom: '16px',
});

const Wallet = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [Amount, setAmount] = useState('');
  const [debitAmount, setDebitAmount] = useState('');

  const { ids, types, fname, lname } = location.state || {};
  console.log(types);
  console.log(ids);
  console.log(fname);
  console.log(lname);
  const isDisableButton = !Amount;
  const isDisabledebitButton = !debitAmount;
  const handleCreateWallet = async (event) => {
    event.preventDefault();
    try {
      const data = {
        userId: ids,
        userType: types.toUpperCase(),
      };
      const res = await axios.post('/module/auth/admin/wallet', data);

      if (res.status === 200) {
        toast.success(res.data.message);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error('An error occurred');
      }
    }
  };
  const handletransaction = async (event) => {
    event.preventDefault();
    try {
      const data = {
        amount: Amount,
        type: 'credit',
        userId: ids,
        userType: types.toUpperCase(),
      };
      const res = await axios.post('/module/auth/admin/transaction', data);
      console.log(res);
      if (res.status === 200) {
        navigate('/dashboard/TransactionHistory');
        toast.success(res.data.message);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error('An error occurred');
      }
    }
  };
  const handledebitTransaction = async (event) => {
    event.preventDefault();
    try {
      const data = {
        amount: debitAmount,
        type: 'debit',
        userId: ids,
        userType: types.toUpperCase(),
      };
      const res = await axios.post('/module/auth/admin/transaction', data);
      console.log(res);
      if (res.status === 200) {
        navigate('/dashboard/TransactionHistory');
        toast.success(res.data.message);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error('An error occurred');
      }
    }
  };
  return (
    <div>
      <Card sx={{ padding: '20px 10px', margin: '30px 0px' }}>
        <Grid container item justifyContent="space-between" alignItems="center" sx={{ marginRight: '10px' }}>
          <Typography variant="h4" gutterBottom>
            {fname} {lname}
          </Typography>

          <Button
            variant="contained"
            onClick={handleCreateWallet}
            startIcon={<WalletIcon icon="icon-park-twotone:back" />}
          >
            Create Wallet
          </Button>
        </Grid>
      </Card>
      <Card sx={{ padding: '20px 10px', margin: '30px 0px' }}>
        <Grid container sx={{ borderBottom: '1px solid #dbe0e4', padding: '10px 0px' }}>
          <Grid container item justifyContent="space-between" alignItems="center" sx={{ marginRight: '10px' }}>
            <Typography variant="h6" gutterBottom sx={{ margin: '0px 0px 0px 10px', paddingBottom: '0px' }}>
              Credit Amount
            </Typography>
          </Grid>
        </Grid>

        <form onSubmit={handletransaction}>
          <Grid container sx={{ marginTop: '10px' }}>
            <Grid item xs={6} sx={{ padding: '0px 5px' }}>
              <TextField
                sx={{ margin: '10px 0px', width: '100%' }}
                id="outlined-number"
                label="Amount"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                value={Amount}
                onChange={(event) => setAmount(event.target.value)}
              />
            </Grid>
            <Grid item xs={6} sx={{ padding: '0px 5px' }}>
              <TextField
                sx={{ margin: '10px 0px', width: '100%' }}
                id="outlined-read-only-input"
                label="PaymentType"
                defaultValue="Credit"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid container justifyContent="flex-end" sx={{ paddingRight: '20px', gap: '10px' }}>
              <Button
                type="submit"
                variant="contained"
                disabled={isDisableButton}
                color="success"
                endIcon={<img src="/assets/icons/card-send.svg" alt="icon" />}
              >
                Credit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>
      <Card sx={{ padding: '20px 10px', margin: '30px 0px' }}>
        <Grid container sx={{ borderBottom: '1px solid #dbe0e4', padding: '10px 0px' }}>
          <Grid container item justifyContent="space-between" alignItems="center" sx={{ marginRight: '10px' }}>
            <Typography variant="h6" gutterBottom sx={{ margin: '0px 0px 0px 10px', paddingBottom: '0px' }}>
              Debit Amount
            </Typography>
          </Grid>
        </Grid>

        <form onSubmit={handledebitTransaction}>
          <Grid container sx={{ marginTop: '10px' }}>
            <Grid item xs={6} sx={{ padding: '0px 5px' }}>
              <TextField
                sx={{ margin: '10px 0px', width: '100%' }}
                id="outlined-number"
                label="Amount"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                value={debitAmount}
                onChange={(event) => setDebitAmount(event.target.value)}
              />
            </Grid>
            <Grid item xs={6} sx={{ padding: '0px 5px' }}>
              <TextField
                sx={{ margin: '10px 0px', width: '100%' }}
                id="outlined-read-only-input"
                label="PaymentType"
                defaultValue="debit"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid container justifyContent="flex-end" sx={{ paddingRight: '20px', gap: '10px' }}>
              <Button
                type="submit"
                variant="contained"
                color="warning"
                disabled={isDisabledebitButton}
                endIcon={<img src="/assets/icons/card-receive.svg" alt="icon" />}
              >
                Debit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>
    </div>
  );
};

export default Wallet;
