import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import ProviderPage from './pages/ProviderPage';
import AdminPage from './pages/AdminPage';
import ListingPage from './pages/ListingPage';
import ListAmenityCategory from './pages/ListAmenityCategory';
import ListProperty from './pages/ListProperty';
import ListCategory from './pages/ListCategory';
import ListAmenityData from './pages/ListAmenityData';
import AddMenityCategory from './pages/AddAmenityCategory';
import Pendings from './pages/PendingBooking';
import Accepted from './pages/AcceptedBooking';
import Booking from './pages/Booking';
import Cancel from './pages/CancelBooking';
import CurrentTrip from './pages/CurrentBookings';
import UpcomingTrip from './pages/UpcomingTrips';
import AddProfile from './pages/AddProfile';
import LoginPage from './pages/LoginPage';
import EditProvider from './pages/EditProvider';
import EditUser from './pages/EditUser';
import EditAdmin from './pages/EditAdmin';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import Offers from './pages/Offers';
import AddOffers from './pages/AddOffer';
import EditOffers from './pages/EditOffer';
import IconPage from './pages/IconPage';
import Addicon from './pages/Addicons';
import Editamenity from './pages/Editamenitycategory';
import Editamenitydata from './pages/Editamenitydata';
import SendNotification from './pages/Notification';
import Notifications from './pages/viewnotification';
import TransactionHistory from './pages/Trancactionhistory';
import Wallet from './pages/Wallet';
import Language from './pages/Language';
import AdminProfile from './pages/AdminProfile';
import Commission from './pages/Commission';

// import Transaction from './pages/Transaction';
// eslint-disable-next-line spaced-comment
//import PerviousTrip from './pages/PerviousTrips';
// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'provider', element: <ProviderPage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'admin', element: <AdminPage /> },
        { path: 'blog', element: <BlogPage /> },
        { path: 'addprofile', element: <AddProfile /> },
        { path: 'editprovider', element: <EditProvider /> },
        { path: 'edituser', element: <EditUser /> },
        { path: 'editadmin', element: <EditAdmin /> },
        { path: 'listing', element: <ListingPage /> },
        { path: 'amenitycategory', element: <ListAmenityCategory /> },
        { path: 'addamenity', element: <AddMenityCategory /> },
        { path: 'property/:propid', element: <ListProperty /> },
        { path: 'category', element: <ListCategory /> },
        { path: 'amenitydata/:catid', element: <ListAmenityData /> },
        { path: 'pendingbooking', element: <Pendings /> },
        { path: 'acceptedbooking', element: <Accepted /> },
        { path: 'bookinghistory', element: <Booking /> },
        { path: 'cancellationhistory', element: <Cancel /> },
        { path: 'currenttrips', element: <CurrentTrip /> },
        { path: 'upcomingtrips', element: <UpcomingTrip /> },
        //    { path: 'previoustrips', element: <PerviousTrip /> },
        { path: 'offers', element: <Offers /> },
        { path: 'addoffers', element: <AddOffers /> },
        { path: 'editoffers', element: <EditOffers /> },
        { path: 'icon', element: <IconPage /> },
        { path: 'addicon', element: <Addicon /> },
        { path: 'editamenitycategory/:ids', element: <Editamenity /> },
        { path: 'editamenitydata/:amenitytId', element: <Editamenitydata /> },
        { path: 'Sendnotification', element: <SendNotification /> },
        { path: 'notifications', element: <Notifications /> },
        { path: 'TransactionHistory', element: <TransactionHistory /> },
        { path: 'wallet', element: <Wallet /> },
        { path: 'Language', element: <Language /> },
        { path: 'AdminProfile', element: <AdminProfile /> },
        { path: 'Commission', element: <Commission /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
