import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Grid,
  Button,
  Typography,
  MenuItem,
  Card,
  Modal,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { toast } from 'react-toastify';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Iconify from '../components/iconify';
import countries from '../Country.json';
import stateData from '../State.json';
import cityData from '../City.json';
import { axios } from '../utils/axios';

export default function EditProvider() {
  const location = useLocation();
  const { datas, editId, types, act } = location.state;
  const navigate = useNavigate();
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [eMails, setEMails] = useState('');
  const [phoneCodes, setPhoneCodes] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [dob, setDob] = useState('');
  const [profilePic, setProfilePic] = useState('');
  const [genders, setGenders] = useState(datas?.gender || '');
  const [addRess, setAddRess] = useState('');
  const [city, setCity] = useState([]);
  const [cities, setCities] = useState('');
  const [state, setState] = useState([]);
  const [selectedStateId, setSelectedStateId] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [stateZip, setStateZip] = useState('');
  const [selectedCountryId, setSelectedCountryId] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [landMark, setLandMark] = useState('');
  const [currencies, SetCurrencies] = useState('');
  const [latitude, setLatitude] = useState('0');
  const [longitude, setLongitude] = useState('0');
  const [instBooking, setInstBooking] = useState('');
  const [openResetModal, setOpenResetModal] = useState(false);
  const [showPasswordc, setShowPasswordc] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [currentPasswords, setCurrentPasswords] = useState('');
  const [changedPassword, setChangedPassword] = useState('');
  const [commissionValue, setCommissionValue] = useState('');
  const [taxValue, setTaxValue] = useState('');
  const [statusValue, setStatusValue] = useState('default');
  const [isAbove18, setIsAbove18] = useState(true);
  const [error, setError] = useState('');
  const [phoneCodeError, setPhoneCodeError] = useState('');
  const [emailError, setEmailError] = useState('');
  const isDisableButton = !isAbove18 || error || phoneCodeError || emailError;

  console.log(datas);
  const style1 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const backtouser = () => {
    navigate('/dashboard/provider');
  };

  const updateProfile = async (ids) => {
    try {
      const updatedValues = new FormData();
      updatedValues.append('file', profilePic || datas?.profileImage);
      updatedValues.append('phone', phoneNumber || datas?.phone);
      updatedValues.append('phoneCode', phoneCodes || datas?.phoneCode);
      updatedValues.append('email', eMails || datas?.email);
      updatedValues.append('DOB', (dob && dob?.format('L')) || datas?.DOB);
      updatedValues.append('firstname', fname || datas?.firstname);
      updatedValues.append('lastname', lname || datas?.lastname);
      updatedValues.append('gender', genders || datas?.gender);
      updatedValues.append('instantBooking', instBooking || datas?.instantBooking);
      // updatedValues.append('currency', currencies || datas?.currency);
      // updatedValues.append('address', addRess || datas?.address.address);
      // updatedValues.append('city', cities || datas?.address.city);
      // updatedValues.append('state', selectedState || datas?.address.state);
      // updatedValues.append('country', selectedCountry || datas?.address.country);
      updatedValues.append('zipcode', stateZip || datas?.address.zipcode);
      // updatedValues.append('landmark', landMark || datas?.address.landmark);
      // updatedValues.append('lat', latitude || datas?.address.coordinates[0]);
      // updatedValues.append('lng', longitude || datas?.address.coordinates[1]);
      updatedValues.append('username', '');
      updatedValues.append('password', '');

      const res = await axios.put(`module/auth/provider/${ids}`, updatedValues);
      if (res.status === 200) {
        toast.success(res.data.message);
        setTimeout(() => {
          navigate('/dashboard/provider');
        }, 1000);
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  const handleCountry = (e) => {
    const getcountryId = e.target.value;
    const getStatedata = stateData.filter((state) => state.country_id === getcountryId);
    setState(getStatedata);
    setSelectedCountryId(getcountryId);
  };

  const handleState = (e) => {
    const getStateId = e.target.value;
    const getCitydata = cityData.filter((state) => state.state_id === getStateId);
    setCity(getCitydata);
    setSelectedStateId(getStateId);
  };
  console.log(dob && dob?.format('L').length);

  const providerVerify = async (ids, boolean) => {
    try {
      const res = await axios.put(
        `module/auth/provider/VerifyAndActiveStatus/${ids}`,
        new URLSearchParams({ verify: boolean })
      );
      if (res.status === 200) {
        toast.info(res.data.message);
        setTimeout(() => {
          navigate('/dashboard/provider');
        }, 1000);
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  const resetModal = async () => {
    try {
      const res = await axios.post(`module/auth/provider/password`, { providerId: editId });
      if (res.status === 200) {
        toast.success('Password Reseted');
        setTimeout(() => {
          navigate('/dashboard/provider');
        }, 1000);
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };
  const changePassword = async () => {
    const newPassword = changedPassword;
    const providerId = editId;
    try {
      const res = await axios.put(`module/auth/provider/password`, { providerId, newPassword });
      if (res.status === 200) {
        setOpenResetModal(false);
        toast.success('password Changed');
        setTimeout(() => {
          navigate('/dashboard/provider');
        }, 1000);
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };
  const handleAddCommission = async () => {
    try {
      const res = await axios.post(`module/listing/comisionAndTax/${editId}`, {
        commission: commissionValue,
        status: statusValue,
        tax: taxValue,
      });
      if (res.status === 200) {
        toast.success('commission Added');
        setTimeout(() => {
          navigate('/dashboard/provider');
        }, 1000);
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };
  useEffect(() => {
    fetchCommissinData();
  }, []);

  const fetchCommissinData = async () => {
    try {
      const res = await axios.get(`module/listing/comisionAndTax/${editId}`);
      console.log('response', res);

      setCommissionValue(res?.data?.data[0]?.commission || '');
      setTaxValue(res?.data?.data[0]?.tax || '');
      setStatusValue(res?.data?.data[0]?.status || '');
    } catch (err) {
      toast.error(err.res.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };
  const handleOpenModal = () => {
    setOpenResetModal(true);
  };
  const handleDateChange = (newValue) => {
    setDob(newValue);
    const currentDate = dayjs();
    const age = currentDate.diff(newValue, 'year');
    setIsAbove18(age >= 18);
  };

  const providerActive = async (ids, boolean) => {
    try {
      const res = await axios.put(
        `module/auth/provider/VerifyAndActiveStatus/${ids}`,
        new URLSearchParams({ active: boolean })
      );
      if (res.status === 200) {
        toast.info(res.data.message);
        navigate('/dashboard/provider');
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };
  const handleMailChange = (e) => {
    const emailInput = e.target.value;
    setEMails(emailInput);

    if (!/[a-z0-9]+@[^\s@]+\.[a-z]{3}$/.test(emailInput)) {
      setEmailError('Invalid email format');
    } else if (/[!#$%^&*()_+|[\]{};:'"<>,?,0-9]/.test(emailInput)) {
      setEmailError('Invalid email format');
    } else {
      setEmailError('');
    }
  };

  const handlePhoneCodeChange = (e) => {
    const codeInput = e.target.value;
    setPhoneCodes(codeInput);

    if (!/^\d{1,3}$/.test(codeInput)) {
      setPhoneCodeError('Invalid phone code');
    } else {
      setPhoneCodeError('');
    }
  };
  const handleChange = (e) => {
    const numericInput = e.target.value;
    setPhoneNumber(numericInput);

    if (!/^\d{10}$/.test(numericInput)) {
      setError('Invalid phone number');
    } else {
      setError('');
    }
  };
  return (
    <>
      <Helmet>
        <title> Edit Profile </title>
      </Helmet>
      <Card sx={{ padding: '20px 10px', margin: '30px 0px' }}>
        <Grid container sx={{ borderBottom: '1px solid #dbe0e4', padding: '10px 0px' }}>
          <Grid container item justifyContent="space-between" alignItems="center" sx={{ marginRight: '10px' }}>
            <Typography variant="h6" gutterBottom sx={{ margin: '0px 0px 0px 10px', paddingBottom: '0px' }}>
              {datas.firstname}
              {datas.lastname}'s profile
            </Typography>
            <Button variant="contained" onClick={backtouser} startIcon={<Iconify icon="icon-park-twotone:back" />}>
              Go Back
            </Button>
          </Grid>
        </Grid>
        <Grid container sx={{ marginTop: '10px' }}>
          <Grid item xs={6} sx={{ padding: '0px 5px' }}>
            <InputLabel id="demo-simple-select-label">First Name</InputLabel>
            <TextField
              sx={{ margin: '10px 0px', width: '100%' }}
              name="FirstName"
              defaultValue={datas.firstname}
              onChange={(e) => {
                const textInput = e.target.value;
                // Use a regular expression to check for alphabetic characters only
                const validTextInput = textInput.replace(/[^a-zA-Z]/g, '');
                setFname(validTextInput);
              }}
              onKeyDown={(e) => {
                // Prevent typing numbers and special characters by capturing keydown events
                if (!/^[a-zA-Z]*$/.test(e.key)) {
                  e.preventDefault();
                }
              }}
            />
          </Grid>
          <Grid item xs={6} sx={{ padding: '0px 5px' }}>
            <InputLabel id="demo-simple-select-label">Last Name</InputLabel>
            <TextField
              sx={{ margin: '10px 0px', width: '100%' }}
              name="LastName"
              defaultValue={datas.lastname}
              onChange={(e) => {
                const textInput = e.target.value;
                // Use a regular expression to check for alphabetic characters only
                const validTextInput = textInput.replace(/[^a-zA-Z]/g, '');
                setLname(validTextInput);
              }}
              onKeyDown={(e) => {
                // Prevent typing numbers and special characters by capturing keydown events
                if (!/^[a-zA-Z]*$/.test(e.key)) {
                  e.preventDefault();
                }
              }}
            />
          </Grid>
          <Grid item xs={6} sx={{ padding: '0px 5px' }}>
            <InputLabel id="demo-simple-select-label">Gender</InputLabel>
            <FormControl fullWidth sx={{ margin: '10px 0px' }}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={genders}
                onChange={(e) => setGenders(e.target.value)}
                renderValue={(selected) => {
                  if (selected === '') {
                    return <em>{genders}</em>;
                  }
                  return selected;
                }}
              >
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sx={{ padding: '0px 5px' }}>
            <InputLabel id="demo-simple-select-label">Email</InputLabel>
            <TextField
              sx={{ margin: '10px 0px', width: '100%' }}
              name="Email"
              error={!!emailError}
              helperText={emailError}
              defaultValue={datas.email}
              onChange={handleMailChange}
            />
          </Grid>
          <Grid item xs={6} sx={{ padding: '0px 5px' }}>
            <InputLabel id="demo-simple-select-label">Phone Code</InputLabel>
            <TextField
              sx={{ margin: '10px 0px', width: '100%' }}
              name="Phonecode"
              defaultValue={datas.phoneCode}
              onChange={handlePhoneCodeChange}
              error={!!phoneCodeError}
              helperText={phoneCodeError}
            />
          </Grid>
          <Grid item xs={6} sx={{ padding: '0px 5px' }}>
            <InputLabel id="demo-simple-select-label">Phone Number</InputLabel>
            <TextField
              sx={{ margin: '10px 0px', width: '100%' }}
              name="phonenumber"
              defaultValue={datas.phone ? datas.phone.slice(2) : ''}
              error={!!error}
              helperText={error}
              onChange={handleChange}
              inputMode="numeric"
            />
          </Grid>
          <Grid item xs={6} sx={{ padding: '0px 5px' }}>
            <InputLabel id="demo-simple-select-label">DOB</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
              <DemoContainer components={['DatePicker']}>
                <DatePicker
                  sx={{ width: 1 }}
                  defaultValue={dayjs(datas.DOB)}
                  format="DD-MM-YYYY"
                  disableFuture
                  onChange={handleDateChange}
                />
              </DemoContainer>
            </LocalizationProvider>
            {!isAbove18 && <p style={{ color: 'red' }}>Age must be above 18</p>}
          </Grid>
          <Grid item xs={6} sx={{ padding: '0px 5px' }}>
            <InputLabel id="demo-simple-select-label">Profile Pic</InputLabel>
            <TextField
              type="file"
              sx={{ margin: '10px 0px', width: '100%' }}
              name="profilepic"
              // defaultValue={datas.profileImage}
              onChange={(e) =>
                // console.log(e.target.files && e.target.files[0])
                setProfilePic(e.target.files && e.target.files[0])
              }
            />
          </Grid>
          <Grid item xs={6} sx={{ padding: '0px 5px' }}>
            <InputLabel id="demo-simple-select-label">ZipCode</InputLabel>
            <TextField
              sx={{ margin: '10px 0px', width: '100%' }}
              name="zipcode"
              defaultValue={datas.address.zipcode}
              onChange={(e) => setStateZip(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} sx={{ padding: '0px 5px' }}>
            <InputLabel id="demo-simple-select-label">Latitude</InputLabel>
            <TextField
              sx={{ margin: '10px 0px', width: '100%' }}
              name="latitude"
              defaultValue={datas.address.coordinates[0]}
              onChange={(e) => setLatitude(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} sx={{ padding: '0px 5px' }}>
            <InputLabel id="demo-simple-select-label">Longitude</InputLabel>
            <TextField
              sx={{ margin: '10px 0px', width: '100%' }}
              name="longitude"
              defaultValue={datas.address.coordinates[1]}
              onChange={(e) => setLongitude(e.target.value)}
            />
          </Grid>
        </Grid>

        <div style={{ display: 'flex', gap: '30px', justifyContent: 'end' }}>
          <Button variant="contained" disabled={isDisableButton} onClick={() => updateProfile(editId)}>
            Update
          </Button>

          <>
            <Grid>
              {!datas.isActive ? (
                <Button color="success" variant="contained" onClick={() => providerActive(editId, true)}>
                  Active
                </Button>
              ) : (
                <Button color="error" variant="contained" onClick={() => providerActive(editId, false)}>
                  InActive
                </Button>
              )}
            </Grid>
            <Grid>
              {!datas.verified ? (
                <Button color="success" variant="contained" onClick={() => providerVerify(editId, true)}>
                  Verify
                </Button>
              ) : (
                <Button color="warning" variant="contained" onClick={() => providerVerify(editId, false)}>
                  Deny
                </Button>
              )}
            </Grid>

            <Grid>
              <Button color="secondary" variant="contained" onClick={() => resetModal(editId)}>
                Reset Password
              </Button>
            </Grid>
            <Grid>
              <Button color="secondary" variant="outlined" onClick={() => handleOpenModal(editId)}>
                Change Password
              </Button>
            </Grid>
          </>
        </div>
      </Card>

      <Card sx={{ padding: '20px 10px', margin: '30px 0px' }}>
        <Grid container sx={{ borderBottom: '1px solid #dbe0e4', padding: '10px 0px' }}>
          <Grid container item justifyContent="space-between" alignItems="center" sx={{ marginRight: '10px' }}>
            <Typography variant="h6" gutterBottom sx={{ margin: '0px 0px 0px 10px', paddingBottom: '0px' }}>
              {types} COMMISSION & TAX
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ marginTop: '10px' }}>
          <Grid item xs={6} sx={{ padding: '0px 5px' }}>
            <InputLabel id="demo-simple-select-label">Commission</InputLabel>
            <TextField
              sx={{ margin: '10px 0px', width: '100%' }}
              name="commission"
              value={commissionValue}
              onChange={(e) => setCommissionValue(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} sx={{ padding: '0px 5px' }}>
            <InputLabel id="demo-simple-select-label">Tax</InputLabel>
            <TextField
              sx={{ margin: '10px 0px', width: '100%' }}
              name="tax"
              value={taxValue}
              onChange={(e) => setTaxValue(e.target.value)}
            />
          </Grid>
          <RadioGroup
            column
            aria-label="status"
            name="status"
            style={{
              paddingLeft: '10px',
            }}
            value={statusValue}
            onChange={(e) => setStatusValue(e.target.value)}
          >
            <FormControlLabel value="active" control={<Radio />} label="Active" />
            <FormControlLabel value="notactive" control={<Radio />} label="Not Active" />
          </RadioGroup>
        </Grid>
        <div style={{ justifyContent: 'flex-end	', display: 'flex' }}>
          <Button
            variant="contained"
            onClick={() => handleAddCommission(editId, commissionValue, taxValue, statusValue)}
          >
            Update
          </Button>
        </div>
      </Card>

      <Modal
        open={openResetModal}
        onClose={() => setOpenResetModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={style1}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Change Password
          </Typography>

          <TextField
            margin="normal"
            required
            fullWidth
            type={showPasswordc ? 'text' : 'password'}
            id="newPassword"
            label="New Password"
            name="new password"
            onChange={(e) => setChangedPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPasswordc(!showPasswordc)} edge="end">
                    <Iconify icon={showPasswordc ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <div className="d-flex justify-content-around mt-3">
            <Button variant="contained" size="medium" color="secondary" onClick={() => setOpenResetModal(false)}>
              No
            </Button>
            <Button variant="contained" size="medium" color="primary" onClick={() => changePassword()}>
              Yes
            </Button>
          </div>
        </Card>
      </Modal>
    </>
  );
}
