import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Card, Grid, Button, Typography, IconButton, InputAdornment, Box } from '@mui/material';
import { toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import { axios } from '../utils/axios';
import Iconify from '../components/iconify';

export default function AddaminPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [amenityCategory, setAmenityCategory] = useState('');
  const [decscribe, setDecscribe] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [imagepreview, setImagepreview] = useState(null);

  const isAddButtonDisabled = !amenityCategory || !decscribe || !imageFile;
  const isAddButtondisabled = !amenityCategory || !decscribe;
  const isAddButtondisable = !amenityCategory || !imageFile;
  const { types, process, id, categories, descs, ids, cate, catid } = location.state;
  console.log(types);
  const backtouser = () => {
    if (types === 'Amenity Category') {
      navigate('/dashboard/amenityCategory');
    } else if (types === 'Category') {
      navigate('/dashboard/category');
    } else if (types === 'Amenity Data') {
      navigate(`/dashboard/amenitydata/${ids}`, { state: { cate: categories, catid: ids } });
    } else {
      navigate(`/dashboard/property/${ids}`, { state: { cate: categories, catids: ids } });
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImagepreview(reader.result);
      setImageFile(file);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const AddAmenityCategory = async () => {
    try {
      const formData = new FormData();
      formData.append('name', amenityCategory);
      formData.append('desc', decscribe);
      formData.append('icon', imageFile);

      if (types === 'Amenity Category') {
        const res = await axios.post('module/admin/amenityCategory', {
          category: amenityCategory,
          desc: decscribe,
        });
        if (res.status === 200) {
          toast.success(res.data.message);
          navigate('/dashboard/amenityCategory');
        } else {
          toast.error(res.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        }
      } else if (types === 'Amenity Data') {
        const res = await axios.post(`module/admin/amenity/${ids}`, formData);

        if (res.status === 200) {
          toast.success(res.data.message);
          navigate(`/dashboard/amenitydata/${ids}`, { state: { cate, catid: ids } });
        } else {
          toast.error(res.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        }
      } else if (types === 'Category') {
        const formData = new FormData();
        formData.append('category', amenityCategory);
        formData.append('icon', imageFile);

        const res = await axios.post('module/admin/category', formData);
        if (res.status === 200) {
          toast.success(res.data.message);
          navigate('/dashboard/category');
        } else {
          toast.error(res.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        }
      } else if (types === 'Property') {
        const formData = new FormData();
        formData.append('property', amenityCategory);
        formData.append('icon', imageFile);

        const res = await axios.post(`module/admin/property/${ids}`, formData);
        if (res.status === 200) {
          toast.success(res.data.message);
          navigate(`/dashboard/property/${ids}`, { state: { cate: categories, catids: ids } });
        } else {
          toast.error(res.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        }
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  const updateAmenityCategory = async (ids) => {
    const token = localStorage.getItem('admintoken');
    const headers = {
      Authorization: `${token}`,
      'Content-Type': 'application/json',
    };
    try {
      const res = await axios.put(`module/admin/amenityCategory/${ids}`, {
        category: categories || amenityCategory,
        desc: descs || decscribe,
      });
      if (res.status === 200) {
        toast.success(res.data.message);
        navigate('/dashboard/amenityCategory');
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  return (
    <>
      <Helmet>
        <title> {process} Amenity Category </title>
      </Helmet>
      <Card sx={{ padding: '20px 10px', margin: '30px 0px' }}>
        <Grid container sx={{ borderBottom: '1px solid #dbe0e4', padding: '10px 0px' }}>
          <Grid container item justifyContent="space-between" alignItems="center" sx={{ marginRight: '10px' }}>
            <Typography variant="h6" gutterBottom sx={{ margin: '0px 0px 0px 10px', paddingBottom: '0px' }}>
              {process} {types}
            </Typography>
            <Button variant="contained" onClick={backtouser} startIcon={<Iconify icon="icon-park-twotone:back" />}>
              Go Back
            </Button>
          </Grid>
        </Grid>
        {process === 'Add' && types === 'Amenity Category' && (
          <Grid container sx={{ marginTop: '10px' }}>
            <Grid item xs={6} sx={{ padding: '0px 5px' }}>
              {<InputLabel id="demo-simple-select-label">Amenity category</InputLabel>}
              <TextField
                sx={{ margin: '10px 0px', width: '100%' }}
                name="FirstName"
                value={amenityCategory}
                onChange={(e) => setAmenityCategory(e.target.value)}
              />
            </Grid>

            <Grid item xs={6} sx={{ padding: '0px 5px' }}>
              <InputLabel id="demo-simple-select-label">Amenity Description</InputLabel>
              <TextField
                sx={{ margin: '10px 0px', width: '100%' }}
                name="LastName"
                value={decscribe}
                onChange={(e) => setDecscribe(e.target.value)}
              />
            </Grid>
          </Grid>
        )}

        {process === 'Add' && types === 'Amenity Data' && (
          <Grid container sx={{ marginTop: '10px' }}>
            <Grid item xs={6} sx={{ padding: '0px 5px' }}>
              {<InputLabel id="demo-simple-select-label">Amenity Name</InputLabel>}
              <TextField
                sx={{ margin: '10px 0px', width: '100%' }}
                name="FirstName"
                value={amenityCategory}
                onChange={(e) => setAmenityCategory(e.target.value)}
              />
            </Grid>

            <Grid item xs={6} sx={{ padding: '0px 5px' }}>
              <InputLabel id="demo-simple-select-label">Amenity Description</InputLabel>
              <TextField
                sx={{ margin: '10px 0px', width: '100%' }}
                name="LastName"
                value={decscribe}
                onChange={(e) => setDecscribe(e.target.value)}
              />
            </Grid>
            <Grid item sx={{ marginLeft: '7px' }} justifyContent="center" alignItems="center">
              <InputLabel
                sx={6}
                id="demo-simple-select-label"
                style={{
                  cursor: 'pointer',
                  border: '1px solid #adb5bd',
                  borderRadius: '5px',
                  padding: '15px',
                }}
              >
                <input
                  type="file"
                  id="image-upload"
                  style={{ display: 'none' }}
                  onChange={(e) => handleImageChange(e)}
                />
                <span style={{ color: 'black' }}>Upload Image</span>
                {imagepreview && (
                  <img
                    src={imagepreview}
                    alt="Preview"
                    style={{ maxWidth: '50px', position: 'relative', left: '25px', width: '70px', marginTop: '8px' }}
                  />
                )}
              </InputLabel>
            </Grid>
          </Grid>
        )}

        {process === 'Add' && types === 'Category' && (
          <Grid container sx={{ marginTop: '10px' }}>
            <Grid item xs={6} sx={{ padding: '0px 5px' }}>
              {<InputLabel id="demo-simple-select-label">Amenity Category</InputLabel>}
              <TextField
                sx={{ margin: '10px 0px', width: '100%' }}
                name="FirstName"
                value={amenityCategory}
                onChange={(e) => setAmenityCategory(e.target.value)}
              />
            </Grid>

            <Grid item sx={{ marginTop: '8px', marginLeft: '150px' }}>
              <InputLabel
                sx={6}
                id="demo-simple-select-label"
                position={'absolute'}
                style={{
                  cursor: 'pointer',
                  border: '1px solid #adb5bd',
                  borderRadius: '5px',
                  padding: '15px',
                  marginTop: '26px',
                  marginLeft: '0px',
                  right: '100px',
                }}
              >
                <input
                  type="file"
                  id="image-upload"
                  style={{ display: 'none' }}
                  onChange={(e) => handleImageChange(e)}
                />
                <span style={{ color: 'black' }}>Upload Image</span>
                {imagepreview && (
                  <img
                    src={imagepreview}
                    alt="Preview"
                    style={{ maxWidth: '50px', position: 'relative', left: '25px', width: '70px', marginTop: '8px' }}
                  />
                )}
              </InputLabel>
            </Grid>
          </Grid>
        )}

        {process === 'Add' && types === 'Property' && (
          <Grid container sx={{ marginTop: '10px' }}>
            <Grid item xs={6} sx={{ padding: '0px 5px' }}>
              {<InputLabel id="demo-simple-select-label">Amenity Category</InputLabel>}
              <TextField
                sx={{ margin: '10px 0px', width: '100%' }}
                name="FirstName"
                value={amenityCategory}
                onChange={(e) => setAmenityCategory(e.target.value)}
              />
            </Grid>

            <Grid item sx={{ marginTop: '8px', marginLeft: '150px' }}>
              <InputLabel
                sx={6}
                id="demo-simple-select-label"
                style={{
                  cursor: 'pointer',
                  border: '1px solid #adb5bd',
                  borderRadius: '5px',
                  padding: '15px',
                  marginTop: '26px',
                  marginLeft: '0px',
                  right: '100px',
                }}
              >
                <input
                  type="file"
                  id="image-upload"
                  style={{ display: 'none' }}
                  onChange={(e) => handleImageChange(e)}
                />
                <span style={{ color: 'black' }}>Upload Image</span>
                {imagepreview && (
                  <img
                    src={imagepreview}
                    alt="Preview"
                    style={{ maxWidth: '50px', position: 'relative', left: '25px', width: '70px', marginTop: '8px' }}
                  />
                )}
              </InputLabel>
            </Grid>
          </Grid>
        )}
        <Grid container justifyContent="flex-end" sx={{ paddingRight: '0px', bottom: '10px' }}>
          {process === 'Add' && (
            <div>
              {types === 'Amenity Data' ? (
                <Button variant="contained" disabled={isAddButtonDisabled} onClick={() => AddAmenityCategory()}>
                  Add
                </Button>
              ) : types === 'Category' || types === 'Property' ? (
                <Button variant="contained" disabled={isAddButtondisable} onClick={() => AddAmenityCategory()}>
                  Add
                </Button>
              ) : (
                <Button variant="contained" disabled={isAddButtondisabled} onClick={() => AddAmenityCategory()}>
                  Add
                </Button>
              )}
            </div>
          )}
          {process === 'Edit' && (
            <Button variant="contained" onClick={() => updateAmenityCategory(id)}>
              Update
            </Button>
          )}
        </Grid>
      </Card>
    </>
  );
}
