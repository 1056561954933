import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Card, Grid, Button, Typography, IconButton, InputAdornment, Box } from '@mui/material';
import { toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import { axios } from '../utils/axios';
import Iconify from '../components/iconify';
import { IMAGE_URL } from '../utils/config';

export default function AddaminPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [amenityCategory, setAmenityCategory] = useState('');
  const [decscribe, setDecscribe] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [imagepreview, setImagepreview] = useState(null);
  const isAddButtonDisabled = !amenityCategory || !decscribe || !imageFile;

  const { types, process, id, categories, descs, amenityid, catid, cate } = location.state;
  console.log(catid);
  console.log(cate);
  useEffect(() => {
    const { categories, descs, icon } = location.state;
    setAmenityCategory(categories);
    setDecscribe(descs);
    setImageFile(IMAGE_URL + icon);
    setImagepreview(IMAGE_URL + icon);
  }, [location.state]);

  const backtouser = () => {
    navigate(`/dashboard/amenitydata/${catid}`, { state: { catid, cate } });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImagepreview(reader.result);
      setImageFile(file);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const updateAmenityCategory = async (id) => {
    try {
      const formData = new FormData();
      formData.append('name', amenityCategory);
      formData.append('desc', decscribe);
      formData.append('icon', imageFile);

      const res = await axios.put(`module/admin/amenity/${id}`, formData);

      if (res.status === 200) {
        toast.success(res.data.message);
        navigate(`/dashboard/amenitydata/${catid}`, { state: { catid, cate } });
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  return (
    <>
      <Helmet>
        <title> {process} Amenity Data </title>
      </Helmet>

      <Card sx={{ padding: '20px 10px', margin: '30px 0px' }}>
        <Grid container sx={{ borderBottom: '1px solid #dbe0e4', padding: '10px 0px' }}>
          <Grid container item justifyContent="space-between" alignItems="center" sx={{ marginRight: '10px' }}>
            <Typography variant="h6" gutterBottom sx={{ margin: '0px 0px 0px 10px', paddingBottom: '0px' }}>
              {process} {types}
            </Typography>
            <Button variant="contained" onClick={backtouser} startIcon={<Iconify icon="icon-park-twotone:back" />}>
              Go Back
            </Button>
          </Grid>
        </Grid>

        <Grid container sx={{ marginTop: '10px' }}>
          <Grid item xs={6} sx={{ padding: '0px 5px' }}>
            <InputLabel id="demo-simple-select-label"> Category Name</InputLabel>
            <TextField
              sx={{ margin: '10px 0px', width: '100%' }}
              name="FirstName"
              // value={amenityCategory}
              defaultValue={categories}
              onChange={(e) => setAmenityCategory(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} sx={{ padding: '0px 5px' }}>
            <InputLabel id="demo-sformDataimple-select-label">Amenity Description</InputLabel>
            <TextField
              sx={{ margin: '10px 0px', width: '100%' }}
              name="LastName"
              // value={decscribe}
              defaultValue={descs}
              onChange={(e) => setDecscribe(e.target.value)}
            />
          </Grid>
          <Grid container xs={6} sx={{ paddingRight: '0px' }} justifyContent="center" alignItems="center">
            <Grid sx={6}>
              <InputLabel
                sx={6}
                id="formDatademo-simple-select-label"
                position={'absolute'}
                style={{
                  cursor: 'pointer',
                  border: '1px solid #adb5bd',
                  borderRadius: '5px',
                  padding: '15px',
                  marginTop: '0px',
                  marginLeft: '0px',
                  right: '162px',
                }}
              >
                <input
                  type="file"
                  id="image-upload"
                  style={{ display: 'none' }}
                  onChange={(e) => handleImageChange(e)}
                />
                <span style={{ color: 'black' }}>Upload Image</span>
                {imagepreview && (
                  <img
                    src={imagepreview}
                    alt="Preview"
                    style={{ maxWidth: '50px', position: 'relative', left: '25px', width: '70px', marginTop: '8px' }}
                  />
                )}
              </InputLabel>
            </Grid>
          </Grid>
        </Grid>

        <Grid container justifyContent="flex-end" sx={{ paddingRight: '20px', bottom: '10px' }}>
          {process === 'Edit' && (
            <Button variant="contained" disabled={isAddButtonDisabled} onClick={() => updateAmenityCategory(id)}>
              Update
            </Button>
          )}
        </Grid>
      </Card>
    </>
  );
}
