import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
  FormControlLabel,
  Modal,
  Box,
  Typography,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { axios } from '../../../utils/axios';

// import CloseIcon from '@mui/icons-material/Close';
// import DoneIcon from '@mui/icons-material/Done';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordc, setShowPasswordc] = useState(false);
  const [email, setEmail] = useState('adminabs123@gmail.com');
  const [femail, setFemail] = useState('');
  const [password, setPassword] = useState('12345678');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [opens, setOpens] = useState(false);
  const [otpOpens, setOtpOpens] = useState(false);
  const fbmodalClose = () => setOpens(false);
  const otpmodalClose = () => setOtpOpens(false);

  const handleClick = async () => {
    try {
      const res = await axios.post('module/auth/admin/login', {
        password,
        email,
        fcmId: '',
      });
      if (res.status === 200) {
        axios.defaults.headers.common.Authorization = res.data.token;
        localStorage.setItem('admintoken', res.data.token);
        localStorage.setItem('adminid', res.data.data._id);
        localStorage.setItem('adminmail', res.data.data.email);
        localStorage.setItem('adminname', res.data.data.firstname);
        localStorage.setItem('adminlastname', res.data.data.lastname);
        localStorage.setItem('adminpic', res.data.data.profileImage);
        navigate('/dashboard');
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  const sendOtp = async () => {
    try {
      const res = await axios.post('module/auth/admin/forgetPassword', {
        email: femail,
      });
      if (res.status === 200) {
        setOpens(false);
        setOtpOpens(true);
        setOtp(res.data.data.otp);
        toast.success(res.data.message, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  const changePassword = async () => {
    try {
      const OTP = otp.toString();
      const res = await axios.put('module/auth/admin/forgetPassword', {
        email: femail,
        otp: OTP,
        newPassword,
        confirmPassword,
      });
      if (res.status === 200) {
        setOpens(false);
        setOtpOpens(false);
        toast.success(res.data.message, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField name="email" label="Email address" value={email} onChange={(e) => setEmail(e.target.value)} />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          value={password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <FormControlLabel value="end" control={<Checkbox />} label="Remember Me" labelPlacement="end" />
        <Link variant="subtitle2" underline="hover" onClick={() => setOpens(true)}>
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Login
      </LoadingButton>

      {/*  forgot password modal */}

      <Modal
        open={opens}
        onClose={fbmodalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style1}>
          <Typography className="text-center" variant="h5" component="h4">
            Forgot Password?
          </Typography>
          <Typography className="text-center" id="modal-modal-title">
            An OTP will be sent to your registered Email
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Enter Email"
            name="email"
            value={femail}
            autoFocus
            onChange={(e) => setFemail(e.target.value)}
          />
          <div className="d-flex justify-content-around">
            <Button variant="contained" size="medium" color="secondary" onClick={() => setOpens(false)}>
              Back
            </Button>
            <Button variant="contained" size="medium" color="primary" onClick={sendOtp}>
              Request OTP
            </Button>
          </div>
        </Box>
      </Modal>

      {/* otp modal */}

      <Modal
        open={otpOpens}
        onClose={otpmodalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style1}>
          <Typography className="text-center" variant="h5" component="h4">
            Change Password
          </Typography>
          {/* <Typography className="text-center" id="modal-modal-title">
            An OTP will be sent to your registered Email
          </Typography> */}
          <TextField
            margin="normal"
            required
            fullWidth
            id="otp"
            label="OTP"
            name="otp"
            value={otp}
            autoFocus
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            type={showPassword ? 'text' : 'password'}
            id="newPassword"
            label="New Password"
            name="new password"
            autoFocus
            onChange={(e) => setNewPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            type={showPasswordc ? 'text' : 'password'}
            id="confirmPassword"
            label="Confirm Password"
            name="confirm password"
            onChange={(e) => setConfirmPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPasswordc(!showPasswordc)} edge="end">
                    <Iconify icon={showPasswordc ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <div className="d-flex justify-content-around">
            {/* <Button variant="contained" size="medium" color="secondary" onClick={() => setOtpOpens(false)} >
              Back
            </Button> */}
            <Button variant="contained" size="medium" color="primary" onClick={changePassword}>
              Change Password
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}
