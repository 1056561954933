import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  styled,
  Modal,
  Box,
  TextField,
  Grid,
  InputAdornment,
} from '@mui/material';
import { toast } from 'react-toastify';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { UserVerify, UserNotVerify } from '../components/icons/UserVerify';
import { axios } from '../utils/axios';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'Id', alignRight: false },
  { id: 'name', label: 'Full Name', alignRight: false },
  // { id: 'gender', label: 'Gender', alignRight: false },
  // { id: 'dob', label: 'Date of Birth', alignRight: false },
  { id: 'mail', label: 'Email ID', alignRight: false },
  // { id: 'phone', label: 'Phone Number', alignRight: false },
  { id: 'verified', label: 'Verified', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  // { id: 'password', label: 'Reset / Change Password', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

// ----------------------------------------------------------------------
const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
const ColorButton = styled(Button)(({ theme }) => ({
  color: 'white',
  backgroundColor: 'red',
}));
const label = { inputProps: { 'aria-label': 'Size switch demo' } };

// const formatDate = (dob) => {
//   const dateObj = new Date(dob);
//   const day = dateObj.getDate().toString().padStart(2, '0');
//   const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
//   const year = dateObj.getFullYear().toString();
//   return `${day}-${month}-${year}`;
// };
// console.log('date', formatDate);

export default function TransactionHistory() {
  const navigate = useNavigate();
  const [opens, setOpens] = useState(false);
  const [openResetModal, setOpenResetModal] = useState(false);
  const [changedPassword, setChangedPassword] = useState('');
  const [currentPasswords, setCurrentPasswords] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordc, setShowPasswordc] = useState(false);
  const [search, setsearch] = useState('');
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [userList, setUserList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [verify, setVerify] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const { vertical, horizontal, open } = verify;

  const fetchData = async () => {
    try {
      let url = `module/auth/admin/transactionHistory?_page=${page}&_limit=${rowsPerPage}`;
      if (search) {
        url += `&search=${search}`;
        setPage(1);
      }
      const res = await axios.get(url);
      if (res.status === 200) {
        setUserList(res.data.data);
        setTotalCount(res.data.totalCount);
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, order, orderBy, search]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = userList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(1);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(1);
    setFilterName(event.target.value);
  };
  const handleOpenModal = () => {
    navigate('/dashboard/transaction');
  };

  return (
    <>
      <Helmet>
        <title> TransactionHistory | Admin panel </title>
      </Helmet>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Transaction History
        </Typography>
        <Button variant="contained" onClick={handleOpenModal} startIcon={<Iconify icon="eva:plus-fill" />}>
          Transaction
        </Button>
      </Stack>

      <Card>
        <UserListToolbar
          numSelected={selected.length}
          filterName={filterName}
          search={search}
          setsearch={setsearch}
          onFilterName={handleFilterByName}
        />
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={3000}
        open={open}
        onClose={() => setVerify({ ...verify, open: false })}
        key={vertical + horizontal}
      >
        <Alert onClose={() => setVerify({ ...verify, open: false })} severity="success" sx={{ width: '100%' }}>
          <AlertTitle>Verified</AlertTitle>
        </Alert>
      </Snackbar>
    </>
  );
}
