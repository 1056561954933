import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack, Modal } from '@mui/material';
// mock
import account from '../../../_mock/account';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
import { axios } from '../../../utils/axios';
import { IMAGE_URL } from '../../../utils/config';
//
import navConfig from './config';

// ----------------------------------------------------------------------

const NAV_WIDTH = 290;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState(null);
  const [data, setData] = useState(null);
  // const [open, setOpen] = useState(false)

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const openModal = async () => {
    const token = localStorage.getItem('admintoken');
    const id = localStorage.getItem('adminid');
    const headers = {
      Authorization: `${token}`,
      'Content-Type': 'application/json',
    };
    try {
      const res = await axios.get(`module/auth/admin/${id}`, { headers });
      if (res.status === 200) {
        navigate('/dashboard/editprofile', { state: { datas: res.data.data, editId: id, types: res.data.type } });
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };
  const AdminProfileData = async () => {
    try {
      const res = await axios.get('module/auth/admin/profile');
      if (res.status === 200) {
        setData(res?.data?.data);
        setImageUrl(res?.data?.data?.profileImage);
        console.log('gv', imageUrl);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    AdminProfileData();
  }, []);
  const handlenavigate = () => {
    navigate('/dashboard/AdminProfile');
  };
  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none" onClick={handlenavigate}>
          <StyledAccount>
            <Avatar src={IMAGE_URL + imageUrl} alt="photoURL" />

            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary', textTransform: 'capitalize' }}>
                {data?.firstname}
                &nbsp;
                {data?.lastname}
              </Typography>

              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {data?.email}
              </Typography>
            </Box>
          </StyledAccount>
        </Link>
      </Box>

      <NavSection data={navConfig} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <>
      <Box
        component="nav"
        sx={{
          flexShrink: { lg: 0 },
          width: { lg: NAV_WIDTH },
        }}
      >
        {isDesktop ? (
          <Drawer
            open
            variant="permanent"
            PaperProps={{
              sx: {
                width: NAV_WIDTH,
                bgcolor: 'background.default',
                borderRightStyle: 'dashed',
              },
            }}
          >
            {renderContent}
          </Drawer>
        ) : (
          <Drawer
            open={openNav}
            onClose={onCloseNav}
            ModalProps={{
              keepMounted: true,
            }}
            PaperProps={{
              sx: { width: NAV_WIDTH },
            }}
          >
            {renderContent}
          </Drawer>
        )}
      </Box>
    </>
  );
}
