import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Grid,
  Button,
  Typography,
  MenuItem,
  Card,
  Modal,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import { toast } from 'react-toastify';
import { axios } from '../utils/axios';

function Commission() {
  const [commissionValue, setCommissionValue] = useState('');
  const [taxValue, setTaxValue] = useState('');
  const [requestRadius, setRequestRadius] = useState('');
  const isdisable = !commissionValue || !taxValue || !requestRadius;
  const handleAddCommission = async () => {
    try {
      const res = await axios.post(`module/admin/server`, {
        commission: commissionValue,
        tax: taxValue,
        requestRadius,
      });
      if (res.status === 200) {
        toast.success('commission & tax Added');
        setCommissionValue('');
        setTaxValue('');
        setRequestRadius('');
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };
  const handleCommissionInputChange = (e) => {
    const input = e.target.value;
    const numericInput = input.replace(/[^0-9]/g, '');
    setCommissionValue(numericInput);
  };
  const handleTaxInputChange = (e) => {
    const input = e.target.value;
    const numericInput = input.replace(/[^0-9]/g, '');
    setTaxValue(numericInput);
  };
  const handleRquestInputChange = (e) => {
    const input = e.target.value;
    const numericInput = input.replace(/[^0-9]/g, '');
    setRequestRadius(numericInput);
  };
  return (
    <Card sx={{ padding: '20px 10px', margin: '30px 0px' }}>
      <Grid container sx={{ borderBottom: '1px solid #dbe0e4', padding: '10px 0px' }}>
        <Grid container item justifyContent="space-between" alignItems="center" sx={{ marginRight: '10px' }}>
          <Typography variant="h6" gutterBottom sx={{ margin: '0px 0px 0px 10px', paddingBottom: '0px' }}>
            COMMISSION & TAX
          </Typography>
        </Grid>
        <Grid>
          <Typography>add default commission & tax</Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ marginTop: '10px' }}>
        <Grid item xs={6} sx={{ padding: '0px 5px' }}>
          <InputLabel id="demo-simple-select-label">Commission</InputLabel>
          <TextField
            sx={{ margin: '10px 0px', width: '100%' }}
            name="commission"
            value={commissionValue}
            onChange={handleCommissionInputChange}
          />
        </Grid>
        <Grid item xs={6} sx={{ padding: '0px 5px' }}>
          <InputLabel id="demo-simple-select-label">Tax</InputLabel>
          <TextField
            sx={{ margin: '10px 0px', width: '100%' }}
            name="tax"
            value={taxValue}
            onChange={handleTaxInputChange}
          />
        </Grid>
        <Grid item xs={6} sx={{ padding: '0px 5px' }}>
          <InputLabel id="demo-simple-select-label">RequestRadius</InputLabel>
          <TextField
            sx={{ margin: '10px 0px', width: '100%' }}
            name="tax"
            value={requestRadius}
            onChange={handleRquestInputChange}
          />
        </Grid>
      </Grid>
      <div style={{ justifyContent: 'flex-end	', display: 'flex' }}>
        <Button disabled={isdisable} variant="contained" onClick={() => handleAddCommission()}>
          Update
        </Button>
      </div>
    </Card>
  );
}

export default Commission;
