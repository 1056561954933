import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Grid,
  styled,
  Modal,
  Box,
  TextField,
  InputLabel,
  FormControl,
  Select,
} from '@mui/material';
import { toast } from 'react-toastify';
import Decline from '../components/icons/Decline';
import 'react-toastify/dist/ReactToastify.css';
import { axios } from '../utils/axios';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'PNmae', label: 'PropertyName', alignRight: false },
  { id: 'bath', label: 'BathRoom', alignRight: false },
  { id: 'bed', label: 'BedRoom', alignRight: false },
  { id: 'city', label: 'City', alignRight: false },
  { id: 'state', label: 'State', alignRight: false },
  { id: 'country', label: 'Country', alignRight: false },
  { id: 'Co-ordinates', label: 'Co-Ordinates', alignRight: false },
  { id: 'publish', label: 'Publish', alignRight: false },
  // { id: 'availability', label: 'Availability', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

// ----------------------------------------------------------------------

const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.propertyName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
const ColorButton = styled(Button)(({ theme }) => ({
  color: 'white',
  backgroundColor: 'red',
}));
const label = { inputProps: { 'aria-label': 'Size switch demo' } };

export default function UserPage() {
  // const navigate = useNavigate();
  const [search, setsearch] = useState('');
  const [open, setOpen] = useState(false);
  const [opens, setOpens] = useState(false);
  const [openApprove, setOpenApprove] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [selectedName, setSelectedName] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [userList, setUserList] = useState([]);
  const [getAmenities, setGetAmenities] = useState([]);
  const [totalCount, setTotalCount] = useState('');
  const [bedRoom, setBedRoom] = useState(0);
  const [bathRoom, setBathRoom] = useState(0);
  const [statusa, setStatusa] = useState('');
  const [reason, setReason] = useState('');
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const fetchData = async () => {
    const params = {};
    if (bedRoom > 0) {
      params.bedRoom = bedRoom;
    }
    if (bathRoom > 0) {
      params.bathRoom = bathRoom;
    }
    if (filterName.length !== 0) {
      params.search = filterName;
    }
    if (selectedName.length !== 0) {
      params.amenities = `[${selectedName}]`;
    }
    if (selectedStatus.includes('listed')) {
      params.list = 'listed';
    }
    if (selectedStatus.includes('unListed')) {
      params.unList = 'unListed';
    }
    if (selectedStatus.includes('inProgress')) {
      params.inProgress = 'inProgress';
    }
    if (selectedStatus.includes('incomplete')) {
      params.inComplete = 'incomplete';
    }

    try {
      let url = `module/listing/all?_page=${page}&_limit=${rowsPerPage}`;
      if (search) {
        url += `&search=${search}`;
        setPage(1);
      }
      const res = await axios.get(url, { params });
      if (res.status === 200) {
        setUserList(res.data.data);
        setTotalCount(res.data.totalCount);
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, filterName, order, orderBy, search]);

  const handleOpenMenu = () => {
    setOpen(true);
  };

  const handleOpenMenu2 = async () => {
    try {
      const res = await axios.get(`module/listing/getAmenities`);
      if (res.status === 200) {
        setGetAmenities(res.data.data);
        setOpen2(true);
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  const handleOpenMenu3 = () => {
    setPage(1);
    fetchData();
    setOpen3(true);
  };

  const handleformdata = async (ids) => {
    // try {
    //   const res = await axios.get(`module/listing/approvedListings?_skip=1&_limit=10`, { headers })
    //   if (res.status === 200) {
    //     navigate('/dashboard/editprofile', { state: { datas: res.data.data, editId: ids } });
    //   } else {
    //     alert(res.data.message)
    //   }
    // } catch (err) {
    //   alert(err.response.data.message)
    // }
  };

  const handleCloseMenu = () => {
    setOpen(false);
  };
  const handleCloseMenu2 = () => {
    setOpen2(false);
  };
  const handleCloseMenu3 = () => {
    setOpen3(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = userList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleOpenModal = () => {
    // navigate('/dashboard/addprofile', { state: { types: type}});
  };

  const declinelistingsmodel = (ids) => {
    sessionStorage.setItem('approveId', ids);
    setOpenApprove(true);
  };

  const approvelistings = async (ids) => {
    try {
      const res = await axios.post(`module/listing/status/${ids}`, {
        status: 'approve',
        reason: reason || '',
      });
      if (res.status === 200) {
        toast.success(res.data.message);
        fetchData();
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  const declinelistings = async () => {
    const ids = sessionStorage.getItem('approveId');
    try {
      const res = await axios.post(`module/listing/status/${ids}`, {
        status: 'decline',
        reason: reason || '',
      });
      if (res.status === 200) {
        toast.success(res.data.message);
        fetchData();
        setOpenApprove(false);
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  const deleteListings = async () => {
    const providerId = sessionStorage.getItem('rowProviderId');
    const ids = sessionStorage.getItem('rowId');
    try {
      const res = await axios.delete(`module/listing/delete/${ids}`, {
        data: {
          provider: providerId,
        },
      });
      if (res.status === 200) {
        toast.success(res.data.message);
        fetchData();
        setOpens(false);
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  const bedIncrement = () => {
    setBedRoom(bedRoom + 1);
  };

  const bathIncrement = () => {
    setBathRoom(bathRoom + 1);
  };

  const bedDecrement = () => {
    if (bedRoom > 0) {
      setBedRoom(bedRoom - 1);
    }
  };

  const bathDecrement = () => {
    if (bathRoom > 0) {
      setBathRoom(bathRoom - 1);
    }
  };

  const clearRoomdata = () => {
    fetchData();
    setBedRoom(0);
    setBathRoom(0);
  };

  const deSelect = () => {
    fetchData();
    setSelectedName([]);
  };

  const clearStatus = () => {
    fetchData();
    setSelectedStatus([]);
  };

  const setParamValue = () => {
    fetchData();
    setOpen(false);
  };

  const setAmenityValue = () => {
    fetchData();
    setOpen2(false);
  };

  const setStatusValue = () => {
    fetchData();
    setOpen3(false);
  };

  const deletemodel = (id, providerId) => {
    setOpens(true);
    sessionStorage.setItem('rowId', id);
    sessionStorage.setItem('rowProviderId', providerId);
  };

  const handleOnChecked = (evt) => {
    if (evt.checked) {
      const newStatus = [...selectedStatus, evt.value];
      setSelectedStatus(newStatus);
    } else {
      setSelectedStatus(selectedStatus.filter((item) => item !== evt.value));
    }
  };

  const handleChecked = (evt) => {
    if (evt.checked) {
      const newSelected = [...selectedName, evt.value];
      setSelectedName(newSelected);
    } else {
      setSelectedName(selectedName.filter((item) => item !== evt.value));
    }
  };

  const handleChangePage = (event, newpage) => {
    setPage(newpage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(1);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(1);
    setFilterName(event.target.value.trimStart());
  };

  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList.length) : 0;

  return (
    <>
      <Helmet>
        <title> Listings | Admin panel </title>
      </Helmet>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Listing
        </Typography>
        <div>
          <Button variant="contained" onClick={handleOpenMenu} sx={{ marginRight: '20px' }}>
            Rooms & Bed
          </Button>
          <Button variant="contained" onClick={() => handleOpenMenu2()} sx={{ marginRight: '20px' }}>
            Amenities
          </Button>
          <Button variant="contained" onClick={() => handleOpenMenu3()} sx={{ marginRight: '20px' }}>
            Listing Status
          </Button>
          {/* <Button variant="contained" onClick={handleOpenModal} startIcon={<Iconify icon="eva:plus-fill" />}>
            Create Listing
          </Button> */}
        </div>
      </Stack>

      <Card>
        <UserListToolbar
          // numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
          search={search}
          setsearch={setsearch}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={userList.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />

              <TableBody>
                {userList.map((row) => {
                  const { _id, propertyName } = row;
                  const selectedUser = selected.indexOf(propertyName) !== -1;

                  return (
                    <TableRow
                      sx={{
                        '&:nth-of-type(odd)': {
                          backgroundColor: '#DDDDDD',
                        },
                        '&:last-child td, &:last-child th': {
                          border: 0,
                        },
                      }}
                      key={_id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={selectedUser}
                    >
                      <TableCell padding="checkbox">
                        {/* <Checkbox checked={selectedUser} onChange={() => handleClick( propertyName)} /> */}
                      </TableCell>

                      <TableCell
                        component="th"
                        scope="row"
                        padding="none"
                        // sx={{ position: 'sticky', left: '0', background: 'white' }}
                      >
                        {row.propertyName}
                      </TableCell>

                      {/* <TableCell align="left">{row.providerData.firstname}</TableCell>

                      <TableCell align="left">{row.providerData.email}</TableCell>
                      <TableCell align="left">{row.providerData.phone}</TableCell> */}

                      <TableCell align="left">{row.accomodation.bathRoom.bathRoomCount}</TableCell>
                      <TableCell>{row.accomodation.bedRoomCount}</TableCell>
                      {/* <TableCell>{row.address.address}</TableCell> */}
                      <TableCell>{row.address.city}</TableCell>
                      <TableCell>{row.address.state}</TableCell>
                      <TableCell>{row.address.country}</TableCell>
                      <TableCell>
                        {row.address.coordinates[0]}, {row.address.coordinates[1]}
                      </TableCell>
                      <TableCell>
                        {row.status === 'pending' ? (
                          <Label color="error">No</Label>
                        ) : (
                          <Label color="success">Yes</Label>
                        )}
                      </TableCell>
                      {/* <TableCell>
                        {row.availability ?
                          <Label color='success'>Yes</Label>
                          :
                          <Label color='error'>No</Label>
                        }
                      </TableCell> */}
                      <TableCell align="left">{row.status}</TableCell>
                      <TableCell align="left">
                        {/* <IconButton onClick={() => handleformdata(row._id)}>
                          <Iconify icon={'eva:edit-fill'} />
                        </IconButton> */}
                        {(row.status === 'publish' || row.status === 'decline') && (
                          <IconButton onClick={() => approvelistings(row._id)}>
                            <Iconify color="disabled" icon={'mdi:approve'} />
                          </IconButton>
                        )}

                        {row.status === 'approve' && (
                          <IconButton onClick={() => declinelistingsmodel(row._id)}>
                            <Decline />
                          </IconButton>
                        )}

                        <IconButton onClick={() => deletemodel(row._id, row.providerData._id)}>
                          <Iconify icon={'eva:trash-2-outline'} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {/* {emptyRows > 0 && (
                  <TableRow style={{ height: 5 * emptyRows }}>
                    <TableCell colSpan={12} />
                  </TableRow>
                )} */}
              </TableBody>

              {userList.length === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          No data found
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalCount || '0'}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>

      {/* Popover  */}

      <Popover
        open={Boolean(open)}
        onClose={handleCloseMenu}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 170, left: 1300 }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        PaperProps={{
          sx: {
            p: 2,
            width: 'auto',
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs m={0}>
            <Typography>BedRoom</Typography>
          </Grid>
          <Grid item xs m={0}>
            <Button onClick={bedDecrement}>
              <Iconify icon={'zondicons:minus-outline'} />
            </Button>
          </Grid>
          <Grid item xs m={0}>
            <Typography>{bedRoom}</Typography>
          </Grid>
          <Grid item xs m={0}>
            <Button sx={{ color: 'error.main' }} onClick={bedIncrement}>
              <Iconify icon={'simple-line-icons:plus'} />
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs m={0}>
            <Typography>BathRoom</Typography>
          </Grid>
          <Grid item xs m={0}>
            <Button onClick={bathDecrement}>
              <Iconify icon={'zondicons:minus-outline'} />
            </Button>
          </Grid>
          <Grid item xs m={0}>
            <Typography>{bathRoom}</Typography>
          </Grid>
          <Grid item xs m={0}>
            <Button sx={{ color: 'error.main' }} onClick={bathIncrement}>
              <Iconify icon={'simple-line-icons:plus'} />
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="space-around" alignItems="center">
          <Grid item xs m={0}>
            <Button sx={{ color: 'error.main' }} onClick={clearRoomdata}>
              Clear
            </Button>
          </Grid>
          <Grid item xs m={0} p={0}>
            <Button sx={{ marginLeft: '20px' }} onClick={() => setParamValue()}>
              Apply
            </Button>
          </Grid>
        </Grid>
      </Popover>

      <Popover
        open={Boolean(open2)}
        onClose={handleCloseMenu2}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 170, left: 1440 }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        PaperProps={{
          sx: {
            p: 2,
            width: 'auto',
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs m={0}>
            {getAmenities.map((list, id) => {
              const { _id, name } = list;
              // const selectedName = selected.indexOf(_id) !== -1;
              return (
                <Typography>
                  <Checkbox
                    checked={selectedName.includes(_id)}
                    value={_id}
                    onChange={(e) => handleChecked(e.target)}
                  />{' '}
                  {name}
                </Typography>
              );
            })}
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="space-around" alignItems="center">
          <Grid item xs m={0}>
            <Button sx={{ color: 'error.main' }} onClick={deSelect}>
              Clear
            </Button>
          </Grid>
          <Grid item xs m={0} p={0}>
            <Button sx={{ marginLeft: '20px' }} onClick={() => setAmenityValue()}>
              Apply
            </Button>
          </Grid>
        </Grid>
      </Popover>

      <Popover
        open={Boolean(open3)}
        onClose={handleCloseMenu3}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 170, left: 1690 }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        PaperProps={{
          sx: {
            p: 2,
            width: 'auto',
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs m={0}>
            <Typography>
              <Checkbox
                checked={selectedStatus.includes('listed')}
                value="listed"
                onChange={(e) => handleOnChecked(e.target)}
              />{' '}
              Listed
            </Typography>
            <Typography>
              <Checkbox
                checked={selectedStatus.includes('unListed')}
                value="unListed"
                onChange={(e) => handleOnChecked(e.target)}
              />{' '}
              UnListed
            </Typography>
            <Typography>
              <Checkbox
                checked={selectedStatus.includes('inProgress')}
                value="inProgress"
                onChange={(e) => handleOnChecked(e.target)}
              />{' '}
              InProgress
            </Typography>
            <Typography>
              <Checkbox
                checked={selectedStatus.includes('incomplete')}
                value="incomplete"
                onChange={(e) => handleOnChecked(e.target)}
              />{' '}
              Incomplete
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="space-around" alignItems="center">
          <Grid item xs m={0}>
            <Button sx={{ color: 'error.main' }} onClick={clearStatus}>
              Clear
            </Button>
          </Grid>
          <Grid item xs m={0} p={0}>
            <Button sx={{ marginLeft: '20px' }} onClick={() => setStatusValue()}>
              Apply
            </Button>
          </Grid>
        </Grid>
      </Popover>

      <Modal
        open={opens}
        onClose={() => setOpens(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style1}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to delete ?
          </Typography>
          <div className="d-flex justify-content-around mt-3">
            <Button variant="contained" size="medium" color="secondary" onClick={() => setOpens(false)}>
              No
            </Button>
            <Button variant="contained" size="medium" color="primary" onClick={() => deleteListings()}>
              Yes
            </Button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openApprove}
        onClose={() => setOpenApprove(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style1}>
          <Grid item xs={6} sx={{ padding: '0px 5px' }}>
            <InputLabel id="demo-simple-select-label">Reason</InputLabel>
            <TextField
              sx={{ margin: '10px 0px', width: '100%' }}
              name="desc"
              onChange={(e) => setReason(e.target.value)}
            />
          </Grid>

          <div className="d-flex justify-content-around mt-3">
            <Button variant="contained" size="medium" color="secondary" onClick={() => setOpenApprove(false)}>
              No
            </Button>
            <Button variant="contained" size="medium" color="primary" onClick={() => declinelistings()}>
              Yes
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}
