import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Card, Grid, Button, Typography, IconButton, InputAdornment } from '@mui/material';
import { toast } from 'react-toastify';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Iconify from '../components/iconify';
import { axios } from '../utils/axios';

export default function AddaminPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [ddesc, setDdesc] = useState('');
  const [dpercent, setDpercent] = useState('');
  const [dtitle, setDtitle] = useState('');
  const [dcode, setDcode] = useState('');
  const [dstart, setDstart] = useState('');
  const [dend, setDend] = useState('');
  const [profilePic, setProfilePic] = useState(false);

  const { data } = location.state;

  const backtouser = () => {
    navigate('/dashboard/offers');
  };

  const updateOffer = async (id) => {
    try {
      const updatedValues = new FormData();
      updatedValues.append('offer', profilePic || data?.file);
      updatedValues.append('percentage', dpercent || data?.percentage);
      updatedValues.append('desc', ddesc || data?.desc);
      updatedValues.append('title', dtitle || data?.title);
      updatedValues.append('code', dcode || data?.code);
      updatedValues.append('startDate', dstart?.format('') || data?.startDate);
      updatedValues.append('endDate', dend?.format('') || data?.endDate);

      const res = await axios.put(`module/listing/discountCode/${id}`, updatedValues);
      if (res.status === 200) {
        toast.success(res.data.message);
        navigate('/dashboard/offers');
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  console.log(data);
  return (
    <>
      <Helmet>
        <title> Edit Offer </title>
      </Helmet>
      <Card sx={{ padding: '20px 10px', margin: '30px 0px' }}>
        <Grid container sx={{ borderBottom: '1px solid #dbe0e4', padding: '10px 0px' }}>
          <Grid container item justifyContent="space-between" alignItems="center" sx={{ marginRight: '10px' }}>
            <Typography variant="h6" gutterBottom sx={{ margin: '0px 0px 0px 10px', paddingBottom: '0px' }}>
              Edit Offer
            </Typography>
            <Button variant="contained" onClick={backtouser} startIcon={<Iconify icon="icon-park-twotone:back" />}>
              Go Back
            </Button>
          </Grid>
        </Grid>
        <Grid container sx={{ marginTop: '10px' }}>
          <Grid item xs={6} sx={{ padding: '0px 5px' }}>
            <InputLabel id="demo-simple-select-label">Dicount Title</InputLabel>
            <TextField
              sx={{ margin: '10px 0px', width: '100%' }}
              name="FirstName"
              defaultValue={data?.title}
              onChange={(e) => setDtitle(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} sx={{ padding: '0px 5px' }}>
            <InputLabel id="demo-simple-select-label">Discount Code</InputLabel>
            <TextField
              sx={{ margin: '10px 0px', width: '100%' }}
              name="LastName"
              defaultValue={data?.code}
              onChange={(e) => setDcode(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} sx={{ padding: '0px 5px' }}>
            <InputLabel id="demo-simple-select-label">Discount Percentage</InputLabel>
            <TextField
              sx={{ margin: '10px 0px', width: '100%' }}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              defaultValue={data?.percentage}
              onChange={(e) => setDpercent(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} sx={{ padding: '0px 5px' }}>
            <InputLabel id="demo-simple-select-label">Discount Description</InputLabel>
            <TextField
              sx={{ margin: '10px 0px', width: '100%' }}
              type="ddesc"
              name="EmailAddress"
              defaultValue={data?.desc}
              onChange={(e) => setDdesc(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} sx={{ padding: '0px 5px' }}>
            <InputLabel id="demo-simple-select-label">Profile Pic</InputLabel>
            <TextField
              type="file"
              sx={{ margin: '10px 0px', width: '100%' }}
              name="profilepic"
              // defaultValue={datas.profileImage}
              onChange={(e) => setProfilePic(e.target.files && e.target.files[0])}
            />
          </Grid>
          <Grid item xs={6} sx={{ padding: '0px 5px' }}>
            <InputLabel id="demo-simple-select-label">Discount Start</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
              <DemoContainer components={['DatePicker']}>
                <DatePicker
                  sx={{ width: 1 }}
                  defaultValue={dayjs(data?.startDate)}
                  minDate={dayjs(data?.startDate)}
                  disablePast
                  format="DD-MM-YYYY"
                  onChange={(newValue) => setDstart(newValue)}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} sx={{ padding: '0px 5px' }}>
            <InputLabel id="demo-simple-select-label">Discount End</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
              <DemoContainer components={['DatePicker']}>
                <DatePicker
                  sx={{ width: 1 }}
                  defaultValue={dayjs(data?.endDate)}
                  minDate={dayjs(data?.endDate)}
                  disablePast
                  format="DD-MM-YYYY"
                  onChange={(newValue) => setDend(newValue)}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end" sx={{ paddingRight: '20px' }}>
          <Button variant="contained" onClick={() => updateOffer(data._id)}>
            Update
          </Button>
        </Grid>
      </Card>
    </>
  );
}
