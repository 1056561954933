import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Grid,
  styled,
  Modal,
  Box,
} from '@mui/material';
import WalletIcon from '@mui/icons-material/Wallet';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { toast } from 'react-toastify';
import { axios } from '../utils/axios';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { UserVerify, UserNotVerify } from '../components/icons/UserVerify';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'Id', alignRight: false },
  { id: 'name', label: 'Full Name', alignRight: false },
  // { id: 'gender', label: 'Gender', alignRight: false },
  // { id: 'dob', label: 'Date of Birth', alignRight: false },
  { id: 'mail', label: 'Email ID', alignRight: false },
  // { id: 'phone', label: 'Phone Number', alignRight: false },
  { id: 'verified', label: 'Verified', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  // { id: 'payments', label: 'Payments', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

// ----------------------------------------------------------------------
const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.firstname.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
const ColorButton = styled(Button)(({ theme }) => ({
  color: 'white',
  backgroundColor: 'red',
}));
const label = { inputProps: { 'aria-label': 'Size switch demo' } };

export default function UserPage() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);
  const [search, setsearch] = useState('');
  const [opens, setOpens] = useState(false);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [adminList, setAdminList] = React.useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const fetchData = async () => {
    try {
      let url = `module/auth/admins?_page=${page}&_limit=${rowsPerPage}`;
      if (search) {
        url += `&search=${search}`;
        setPage(1);
      }
      const res = await axios.get(url);
      if (res.status === 200) {
        setAdminList(res.data.data);
        setTotalCount(res.data.totalCount);
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, order, orderBy, search]);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };
  const handleformdata = async (ids, list) => {
    try {
      const res = await axios.get(`module/auth/admin/${ids}`);
      if (res.status === 200) {
        navigate('/dashboard/editadmin', {
          state: { datas: res.data.data, editId: ids, types: res.data.type, act: list },
        });
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = adminList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleOpenModal = () => {
    const type = 'Admin';
    navigate('/dashboard/addprofile', { state: { types: type } });
  };

  const adminVerify = async (ids, boolean) => {
    try {
      const res = await axios.put(`module/auth/admin/VerifyAndActiveStatus/${ids}`, { verify: boolean });
      if (res.status === 200) {
        window.location.reload(true);
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  const adminActive = async (ids, boolean) => {
    try {
      const res = await axios.put(`module/auth/admin/VerifyAndActiveStatus/${ids}`, { status: boolean });
      if (res.status === 200) {
        fetchData();
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  const deleteModal = (ids) => {
    sessionStorage.setItem('rowAdminId', ids);
    setOpens(true);
  };

  const deleteAdmin = async () => {
    const ids = sessionStorage.getItem('rowAdminId');
    try {
      const res = await axios.delete(`module/auth/admin/${ids}`);
      if (res.status === 200) {
        toast.info(res.data.message);
        setOpens(false);
        fetchData();
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(1);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(1);
    setFilterName(event.target.value);
  };
  // const createwallet = (ids, fname, lname) => {
  //   const type = 'Admin';
  //   navigate('/dashboard/wallet', { state: { ids, fname, lname, types: type } });
  // };
  return (
    <>
      <Helmet>
        <title> Admin | Admin panel </title>
      </Helmet>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Admin List
        </Typography>
        <Button variant="contained" onClick={handleOpenModal} startIcon={<PersonAddAltIcon />}>
          Add Admin
        </Button>
      </Stack>

      <Card>
        <UserListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
          search={search}
          setsearch={setsearch}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={adminList.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />

              <TableBody>
                {adminList.map((row) => {
                  const { id, firstname } = row;
                  const selectedUser = selected.indexOf(firstname) !== -1;

                  return (
                    <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                      <TableCell padding="checkbox">
                        {/* <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, firstname)} /> */}
                      </TableCell>

                      <TableCell component="th" scope="row" padding="none">
                        {row._id}
                      </TableCell>

                      <TableCell align="left">
                        {row.firstname} {row.lastname}
                      </TableCell>
                      {/* <TableCell align="left">{row.gender}</TableCell>
                      <TableCell align="left">{row.DOB}</TableCell> */}

                      <TableCell align="left">{row.email}</TableCell>
                      {/* <TableCell align="left">{row.phone}</TableCell> */}

                      <TableCell align="left">
                        {!row.verified ? (
                          <Label sx={{ width: '40px', height: '40px' }} color="error">
                            <UserNotVerify />
                          </Label>
                        ) : (
                          <Label sx={{ width: '40px', height: '40px' }} color="success">
                            <UserVerify />
                          </Label>
                        )}
                      </TableCell>

                      <TableCell align="left">
                        {!row.isActive ? <Label color="error">InActive</Label> : <Label color="success">Active</Label>}
                      </TableCell>
                      {/* <TableCell align="left">
                        <Label onClick={() => createwallet(row._id, row.firstname, row.lastname)}>
                          <img src="/assets/icons/card.svg" alt="Create Wallet" />
                        </Label>
                      </TableCell> */}
                      <TableCell align="left">
                        <IconButton onClick={() => handleformdata(row._id, 'adminlist')}>
                          <Iconify icon={'carbon:view'} />
                        </IconButton>
                        <IconButton onClick={() => deleteModal(row._id)}>
                          <Iconify icon={'eva:trash-2-outline'} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>

              {adminList.length === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          No data found
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalCount || '0'}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>

      <Modal
        open={opens}
        onClose={() => setOpens(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style1}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to delete ?
          </Typography>
          <div className="d-flex justify-content-around mt-3">
            <Button variant="contained" size="medium" color="secondary" onClick={() => setOpens(false)}>
              No
            </Button>
            <Button variant="contained" size="medium" color="primary" onClick={() => deleteAdmin()}>
              Yes
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}
