// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`${process.env.PUBLIC_URL}/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const navConfig = [
  {
    title: 'User',
    path: '/dashboard/user',
    icon: icon('ic_user'),
  },
  {
    title: 'Provider',
    path: '/dashboard/provider',
    icon: icon('ic_user'),
  },
  {
    title: 'Admin',
    path: '/dashboard/admin',
    icon: icon('ic_user'),
  },
  {
    title: 'Icons',
    path: '/dashboard/icon',
    icon: icon('ic_user'),
  },
  {
    title: 'Listing',
    path: '/dashboard/listing',
    icon: icon('ic_user'),
    optionTitle: 'Manage Booking',
    options: [
      {
        title: 'Pending Booking',
        path: '/dashboard/pendingbooking',
        icon: icon('ic_analytics'),
      },
      {
        title: 'Accepted Booking',
        path: '/dashboard/acceptedbooking',
        icon: icon('ic_analytics'),
      },
      {
        title: 'Booking History',
        path: '/dashboard/bookinghistory',
        icon: icon('ic_analytics'),
      },
      {
        title: 'Cancellation History',
        path: '/dashboard/cancellationhistory',
        icon: icon('ic_analytics'),
      },
    ],
  },
  {
    title: 'List Amenity',
    path: '/dashboard/amenitycategory',
    icon: icon('ic_user'),
  },
  {
    title: 'List Category',
    path: '/dashboard/category',
    icon: icon('ic_user'),
    // optionTitle: 'Manage Trips',
    // options: [
    //   {
    //     title: 'Current Trip',
    //     path: '/dashboard/currenttrips',
    //     icon: icon('ic_analytics'),
    //   },
    //   {
    //     title: 'Upcoming Trip',
    //     path: '/dashboard/upcomingtrips',
    //     icon: icon('ic_analytics'),
    //   },
    // ],
  },

  // {
  //   title: 'Transaction History',
  //   path: '/dashboard/TransactionHistory',
  //   icon: icon('ic_user'),
  // },
  {
    title: 'Language',
    path: '/dashboard/language',
    icon: icon('ic_user'),
  },
  {
    title: 'Offers',
    path: '/dashboard/offers',
    icon: icon('ic_user'),
  },
  {
    title: 'Commission & Tax',
    path: '/dashboard/commission',
    icon: icon('ic_user'),
  },
];

export default navConfig;
