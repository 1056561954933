import React, { useState, useEffect } from 'react';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
  IconButton,
  Popover,
  List,
  ListItemText,
} from '@mui/material';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { StyledNavItem, StyledNavItemIcon } from '../../../components/nav-section/styles';
// mocks_
// import account from '../../../_mock/account';
import Iconify from '../../../components/iconify';
import { axios } from '../../../utils/axios';
import { IMAGE_URL } from '../../../utils/config';
// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Dashboard',
    icon: 'eva:home-fill',
    path: '/dashboard/app',
  },
  {
    label: 'Profile',
    icon: 'eva:person-fill',
    path: '/dashboard/AdminProfile',
  },
  {
    label: 'Settings',
    icon: 'eva:settings-2-fill',
    path: '/dashboard/category',
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState(null);
  const [data, setData] = useState(null);
  const logOut = () => {
    localStorage.removeItem('admintoken');
    localStorage.removeItem('adminid');
    localStorage.removeItem('adminmail');
    localStorage.removeItem('adminname');
    localStorage.removeItem('adminlastname');
    setOpen(null);
    navigate('/login');
  };
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleSendIconClick = () => {
    navigate('/dashboard/Sendnotification');
  };
  const handleviewnotification = () => {
    navigate('/dashboard/Notifications');
  };
  const AdminProfileData = async () => {
    try {
      const res = await axios.get('module/auth/admin/profile');
      if (res.status === 200) {
        setData(res?.data?.data);
        setImageUrl(res?.data?.data?.profileImage);
        console.log('gv', imageUrl);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    AdminProfileData();
  }, []);

  return (
    <>
      <div style={{ marginRight: '15px', cursor: 'pointer' }}>
        <NotificationsNoneIcon sx={{ color: 'black' }} onClick={handleviewnotification} />
      </div>
      <div style={{ marginRight: '15px', cursor: 'pointer' }}>
        <SendRoundedIcon sx={{ color: '#0A6EBD' }} onClick={handleSendIconClick} />
      </div>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar sx={{ boxShadow: 2 }} src={IMAGE_URL + imageUrl} alt="photoURL" />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={() => setOpen(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap sx={{ textTransform: 'capitalize' }}>
            {data?.firstname}
            &nbsp;
            {data?.lastname}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {data?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <List>
          {MENU_OPTIONS.map((option) => (
            <StyledNavItem sx={{ p: 1 }} key={option.label} component={RouterLink} to={option.path}>
              <StyledNavItemIcon>
                <Iconify icon={option.icon} />
              </StyledNavItemIcon>
              <ListItemText disableTypography primary={option.label} onClick={() => setOpen(null)} />
            </StyledNavItem>
          ))}
        </List>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={logOut} sx={{ m: 1 }}>
          <Iconify sx={{ mr: 1 }} icon="material-symbols:logout" /> Logout
        </MenuItem>
      </Popover>
    </>
  );
}
