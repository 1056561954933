import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Card, Grid, Button, Typography, IconButton, InputAdornment, Box } from '@mui/material';
import { toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import { axios } from '../utils/axios';
import Iconify from '../components/iconify';

export default function AddaminPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [amenityCategory, setAmenityCategory] = useState('');
  const [decscribe, setDecscribe] = useState('');
  const isAddButtonDisabled = !amenityCategory || !decscribe;
  const { types, process, id, categories, descs } = location.state;

  const backtouser = () => {
    if (types === 'Amenity Category' || types === 'Amenity Data') {
      navigate('/dashboard/amenityCategory');
    } else {
      navigate('/dashboard/category');
    }
  };
  useEffect(() => {
    const { categories, descs } = location.state;
    setAmenityCategory(categories);
    setDecscribe(descs);
  }, [location.state]);

  const updateAmenityCategory = async (ids) => {
    try {
      const res = await axios.put(`module/admin/amenityCategory/${ids}`, {
        category: amenityCategory,
        desc: decscribe,
      });

      if (res.status === 200) {
        navigate('/dashboard/amenityCategory');
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  return (
    <>
      <Helmet>
        <title> {process} Amenity Category </title>
      </Helmet>

      <Card sx={{ padding: '20px 10px', margin: '30px 0px' }}>
        <Grid container sx={{ borderBottom: '1px solid #dbe0e4', padding: '10px 0px' }}>
          <Grid container item justifyContent="space-between" alignItems="center" sx={{ marginRight: '10px' }}>
            <Typography variant="h6" gutterBottom sx={{ margin: '0px 0px 0px 10px', paddingBottom: '0px' }}>
              {process} {types}
            </Typography>
            <Button variant="contained" onClick={backtouser} startIcon={<Iconify icon="icon-park-twotone:back" />}>
              Go Back
            </Button>
          </Grid>
        </Grid>

        {process === 'Edit' && (
          <Grid container sx={{ marginTop: '10px' }}>
            <Grid item xs={6} sx={{ padding: '0px 5px' }}>
              <InputLabel id="demo-simple-select-label">Amenity Category</InputLabel>
              <TextField
                sx={{ margin: '10px 0px', width: '100%' }}
                name="FirstName"
                // value={amenityCategory}
                defaultValue={categories}
                onChange={(e) => setAmenityCategory(e.target.value)}
              />
            </Grid>
            <Grid item xs={6} sx={{ padding: '0px 5px' }}>
              <InputLabel id="demo-simple-select-label">Amenity Description</InputLabel>
              <TextField
                sx={{ margin: '10px 0px', width: '100%' }}
                name="LastName"
                // value={decscribe}
                defaultValue={descs}
                onChange={(e) => setDecscribe(e.target.value)}
              />
            </Grid>
          </Grid>
        )}

        <Grid container justifyContent="flex-end" sx={{ paddingRight: '20px', bottom: '10px' }}>
          {process === 'Edit' && (
            <Button variant="contained" disabled={isAddButtonDisabled} onClick={() => updateAmenityCategory(id)}>
              Update
            </Button>
          )}
        </Grid>
      </Card>
    </>
  );
}
