import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Card, Grid, Button, Typography, IconButton, InputAdornment } from '@mui/material';
import { toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import { axios } from '../utils/axios';
import Iconify from '../components/iconify';

export default function AddaminPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [error, setError] = useState('');
  const [phoneCode, setPhoneCode] = useState('');
  const [phoneCodeError, setPhoneCodeError] = useState('');

  const { types } = location.state;
  console.log(types);
  console.log(email);
  console.log(password);
  const isAddButtonDisabled = !fname || !lname || !phone || !email || !password || emailError;
  const backtouser = () => {
    if (types === 'Admin') {
      navigate('/dashboard/admin');
    } else if (types === 'User') {
      navigate('/dashboard/user');
    } else {
      navigate('/dashboard/provider');
    }
  };

  const addProfile = async () => {
    try {
      if (types === 'Admin') {
        const res = await axios.post('module/auth/admin', {
          email,
          password,
          phoneCode,
          phone,
          firstname: fname,
          lastname: lname,
        });
        if (res.status === 200) {
          toast.success(res.data.message);
          navigate('/dashboard/admin');
        } else {
          toast.error(res.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        }
      } else if (types === 'User') {
        const res = await axios.post('module/auth/user', {
          email,
          password,
          phoneCode,
          phone,
          firstname: fname,
          lastname: lname,
        });
        if (res.status === 200) {
          toast.success(res.data.message);
          navigate('/dashboard/user');
        } else {
          toast.error(res.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        }
      } else {
        const res = await axios.post('module/auth/provider', {
          email,
          password,
          phoneCode,
          phone,
          firstname: fname,
          lastname: lname,
        });
        if (res.status === 200) {
          toast.success(res.data.message);
          navigate('/dashboard/provider');
        } else {
          toast.error(res.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        }
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };
  // const handleChange = (e) => {
  //   const numericInput = e.target.value;
  //   // Use a regular expression to keep only numeric characters
  //   const validNumericInput = numericInput.replace(/\D/g, '');

  //   // Ensure the input has exactly 10 numeric characters
  //   if (validNumericInput.length <= 10) {
  //     setPhone(validNumericInput);
  //     setError(''); // Clear the error message if input is valid
  //   } else {
  //     setPhone(validNumericInput);
  //     setError('Phone number must have 10 digits');
  //   }
  // };
  const handlePhoneCodeChange = (e) => {
    const codeInput = e.target.value;
    setPhoneCode(codeInput);

    if (!/^\d{1,3}$/.test(codeInput)) {
      setPhoneCodeError('Invalid phone code');
    } else {
      setPhoneCodeError('');
    }
  };
  const handleChange = (e) => {
    const numericInput = e.target.value;
    setPhone(numericInput);

    if (!/^\d{10}$/.test(numericInput)) {
      setError('Invalid phone number');
    } else {
      setError('');
    }
  };
  const handleMailChange = (e) => {
    const emailInput = e.target.value;
    setEmail(emailInput);

    if (!/[a-z0-9]+@[^\s@]+\.[a-z]{3}$/.test(emailInput)) {
      setEmailError('Invalid email format');
    } else if (/[!#$%^&*()_+|[\]{};:'"<>,?,0-9]/.test(emailInput)) {
      setEmailError('Invalid email format');
    } else {
      setEmailError('');
    }
  };
  return (
    <>
      <Helmet>
        <title> Add {types} </title>
      </Helmet>
      <Card sx={{ padding: '20px 10px', margin: '30px 0px' }}>
        <Grid container sx={{ borderBottom: '1px solid #dbe0e4', padding: '10px 0px' }}>
          <Grid container item justifyContent="space-between" alignItems="center" sx={{ marginRight: '10px' }}>
            <Typography variant="h6" gutterBottom sx={{ margin: '0px 0px 0px 10px', paddingBottom: '0px' }}>
              Add {types}
            </Typography>
            <Button variant="contained" onClick={backtouser} startIcon={<Iconify icon="icon-park-twotone:back" />}>
              Go Back
            </Button>
          </Grid>
        </Grid>
        <Grid container sx={{ marginTop: '10px' }}>
          <Grid item xs={6} sx={{ padding: '0px 5px' }}>
            <InputLabel id="demo-simple-select-label">First Name</InputLabel>
            <TextField
              sx={{ margin: '10px 0px', width: '100%' }}
              name="FirstName"
              value={fname}
              onChange={(e) => {
                const textInput = e.target.value;
                // Use a regular expression to check for alphabetic characters only
                const validTextInput = textInput.replace(/[^a-zA-Z]/g, '');
                setFname(validTextInput);
              }}
            />
          </Grid>
          <Grid item xs={6} sx={{ padding: '0px 5px' }}>
            <InputLabel id="demo-simple-select-label">Last Name</InputLabel>
            <TextField
              sx={{ margin: '10px 0px', width: '100%' }}
              name="LastName"
              value={lname}
              onChange={(e) => {
                const textInput = e.target.value;
                // Use a regular expression to check for alphabetic characters only
                const validTextInput = textInput.replace(/[^a-zA-Z]/g, '');
                setLname(validTextInput);
              }}
            />
          </Grid>
          <Grid item xs={6} sx={{ padding: '0px 5px' }}>
            <InputLabel id="demo-simple-select-label">PhoneCode</InputLabel>
            <TextField
              sx={{ margin: '10px 0px', width: '100%' }}
              value={phoneCode}
              onChange={handlePhoneCodeChange}
              error={!!phoneCodeError}
              helperText={phoneCodeError}
            />
          </Grid>
          <Grid item xs={6} sx={{ padding: '0px 5px' }}>
            <InputLabel id="demo-simple-select-label">Phone number</InputLabel>
            <TextField
              sx={{ margin: '10px 0px', width: '100%' }}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              value={phone}
              error={!!error}
              helperText={error}
              onChange={handleChange}
              inputMode="numeric"
            />
          </Grid>
          <Grid item xs={6} sx={{ padding: '0px 5px' }}>
            <InputLabel id="demo-simple-select-label">Email</InputLabel>
            <TextField
              sx={{ margin: '10px 0px', width: '100%' }}
              type="email"
              name="EmailAddress"
              error={!!emailError}
              helperText={emailError}
              value={email}
              onChange={handleMailChange}
            />
          </Grid>
          <Grid item xs={6} sx={{ padding: '0px 5px' }}>
            <InputLabel id="demo-simple-select-label">Password</InputLabel>
            <TextField
              sx={{ margin: '10px 0px', width: '100%' }}
              type={showPassword ? 'text' : 'password'}
              value={password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end" sx={{ paddingRight: '20px' }}>
          <Button variant="contained" disabled={isAddButtonDisabled} onClick={() => addProfile()}>
            Add
          </Button>
        </Grid>
      </Card>
    </>
  );
}
