import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, List, ListItemText, Button, Collapse } from '@mui/material';
import { useState } from 'react';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';
import Iconify from '../iconify';

NavSection.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info, options, optionTitle } = item;
  const [showOptions, setShowOptions] = useState(false);
  const handleToggleOptions = () => {
    setShowOptions(!showOptions);
  };

  return (
    <>
      <StyledNavItem
        component={RouterLink}
        to={path}
        sx={{
          '&.active': {
            color: 'text.primary',
            bgcolor: 'action.selected',
            fontWeight: 'fontWeightBold',
          },
        }}
      >
        <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
        <ListItemText disableTypography primary={title} />
        {info && info}
      </StyledNavItem>

      {options && options.length > 0 && (
        <>
          <Button
            onClick={handleToggleOptions}
            sx={{
              width: '100%',
              color: '#202c36',
              margin: '10px 0px',
              padding: '10px 0px',
              '&:hover': {
                background: '#e8ebee',
              },
              ...(showOptions && {
                background: '#e8ebee',
                color: '#202c36',
                margin: '10px 0px',
                padding: '10px 0px',
              }),
            }}
          >
            {showOptions ? (
              <>
                <StyledNavItemIcon>
                  <img src={`./assets/icons/navbar/ic_disabled.svg`} alt="" style={{ width: 16, height: 16 }} />
                </StyledNavItemIcon>
                <ListItemText sx={{ textAlign: 'start' }} disableTypography primary={optionTitle}/>
                <Button
                  startIcon={<Iconify icon="il:arrow-up" color="#a4abb3" style={{ width: '13px', height: '13px' }} />}
                />
              </>
            ) : (
              <>
                <StyledNavItemIcon>
                  <img src={`./assets/icons/navbar/ic_disabled.svg`} alt="" style={{ width: 16, height: 16 }} />
                </StyledNavItemIcon>
                <ListItemText sx={{ textAlign: 'start' }} disableTypography primary={optionTitle} />
                <Button
                  startIcon={<Iconify icon="il:arrow-down" color="#a4abb3" style={{ width: '13px', height: '13px' }} />}
                />
              </>
            )}
          </Button>

          <Collapse in={showOptions} timeout={300}>
            <List>
              {options.map((option) => (
                <StyledNavItem key={option.title} component={RouterLink} to={option.path}>
                  <StyledNavItemIcon>{option.icon && option.icon}</StyledNavItemIcon>
                  <ListItemText disableTypography primary={option.title} />
                  {option.info && option.info}
                </StyledNavItem>
              ))}
            </List>
          </Collapse>
        </>
      )}
    </>
  );
}
