import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Grid,
  styled,
} from '@mui/material';
import { axios } from '../utils/axios';
import { BASE_URL } from '../utils/config';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'notificationId', label: 'NotificationId', alignRight: false },
  { id: 'notificationcreated', label: 'Created', alignRight: false },
  // { id: 'forWhom', label: 'ForWhom', alignRight: false },
  { id: 'fromWhom', label: 'FromWhom', alignRight: false },
  { id: 'userType', label: 'UserType', alignRight: false },
  { id: 'message', label: 'Message', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const ColorButton = styled(Button)(({ theme }) => ({
  color: 'white',
  backgroundColor: 'red',
}));
const label = { inputProps: { 'aria-label': 'Size switch demo' } };

export default function UserPage() {
  const [search, setsearch] = useState('');
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [notoficationList, setnotoficationList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [userList, setUserList] = useState([]);
  const [providerList, setProviderList] = useState([]);

  const fetchData = async () => {
    try {
      const url = `module/notifications/lists?_page=${page}&_limit=${rowsPerPage}`;
      const res = await axios.get(url);
      if (res.status === 200) {
        console.log('response', res);
        setnotoficationList(res.data.data[0].data);
        setTotalCount(res.data.data[0].totalCount[0].total);
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  const deleteNotification = async (id) => {
    try {
      const res = await axios.put(`/module/notifications/${id}`);
      if (res.status === 200) {
        fetchData();
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };
  // const fetchUserList = async () => {
  //   try {
  //     const response = await axios.get(`module/auth/users?_page=1&_limit=10`);
  //     setUserList(response.data.data);
  //     console.log('userdata', response);
  //   } catch (error) {
  //     console.error('Error fetching user list:', error);
  //   }
  // };

  // const fetchProviderList = async () => {
  //   try {
  //     const response = await axios.get(`module/auth/providers?_page=1&_limit=10`);
  //     setProviderList(response.data.data);
  //     console.log('providerdata', response);
  //   } catch (error) {
  //     console.error('Error fetching provider list:', error);
  //   }
  // };

  useEffect(() => {
    fetchData();
    // fetchUserList();
    // fetchProviderList();
  }, [page, rowsPerPage, order, orderBy, search]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(1);
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = notoficationList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  return (
    <>
      <Helmet>
        <title>List Notification </title>
      </Helmet>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Listing Notifications
        </Typography>
      </Stack>
      <Card>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={notoficationList.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {notoficationList.map((row) => {
                  const { id, forWhom, fromWhom, userType, name } = row;
                  const selectedUser = selected.indexOf(name) !== -1;

                  const user = userList.find((user) => user._id === forWhom);
                  console.log('res', user);

                  const provider = providerList.find((provider) => provider._id === forWhom);
                  console.log('res1', provider);

                  const dateObjectCreated = new Date(row?.createdAt);

                  const formattedCreated = `${dateObjectCreated.getDate()}-${
                    dateObjectCreated.getMonth() + 1
                  }-${dateObjectCreated.getFullYear()}`;

                  return (
                    <TableRow
                      sx={{
                        '&:nth-of-type(odd)': {
                          backgroundColor: '#DDDDDD',
                        },
                        '&:last-child td, &:last-child th': {
                          border: 0,
                        },
                      }}
                      key={id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={selectedUser}
                    >
                      <TableCell padding="checkbox">
                        {/* <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} /> */}
                      </TableCell>
                      <TableCell align="left">{row._id}</TableCell>
                      <TableCell align="left" style={{ whiteSpace: 'nowrap' }}>
                        {formattedCreated}
                        {/* {row.createdAt?.split('T')[0]} */}
                      </TableCell>
                      {/* <TableCell align="left">
                        {row.userType === 'USER' ? (user ? `${user.firstname} ${user.lastname}` : '') : ''}
                        {row.userType === 'PROVIDER'
                          ? provider
                            ? `${provider.firstname} ${provider.lastname}`
                            : ''
                          : ''}
                      </TableCell> */}

                      <TableCell align="left">{row.fromWhom}</TableCell>
                      <TableCell align="left">{row.userType}</TableCell>
                      <TableCell align="left">{row.message}</TableCell>
                      <TableCell align="left">
                        <Label color="success">{row.status}</Label>
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => deleteNotification(row._id)}>
                          <Iconify icon={'eva:trash-2-outline'} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              {notoficationList.length === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Typography variant="h6" paragraph sx={{ my: 3, px: 3 }}>
        Notes *
      </Typography>
      <></>
    </>
  );
}
