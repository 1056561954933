import React from 'react'

function Decline() {
  return (
    <svg width="20" height="20" viewBox="0 0 12 12" fill="#637381" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_17_4951)">
<path d="M11.5 6.00002L10.28 4.61002L10.45 2.77002L8.645 2.36002L7.7 0.77002L6 1.50002L4.3 0.77002L3.355 2.36002L1.55 2.76502L1.72 4.60502L0.5 6.00002L1.72 7.39002L1.55 9.23502L3.355 9.64502L4.3 11.235L6 10.5L7.7 11.23L8.645 9.64002L10.45 9.23002L10.28 7.39002L11.5 6.00002Z" fill="#637381"/>
<path d="M5.99996 5.29289L4.2322 3.52513L3.52509 4.23223L5.29286 6L3.52509 7.76777L4.2322 8.47487L5.99996 6.70711L7.76773 8.47487L8.47484 7.76777L6.70707 6L8.47484 4.23223L7.76773 3.52513L5.99996 5.29289Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_17_4951">
<rect width="12" height="12" fill="white"/>
</clipPath>
</defs>
</svg>

  )
}

export default Decline